import React, { useState, useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import useAxiosInstance from '../utils/axiosInstance';
import { TiArrowSortedDown } from 'react-icons/ti';
import { useChatContext } from '../context/chatContext';

const Setting = ({ setModalOpen }) => {
  const axiosInstance = useAxiosInstance();
  const { user, settingResponseType, setSettingResponseType } = useChatContext();
  const [responseType, setResponseType] = useState(settingResponseType || 'accurate');
  const [instructions, setInstructions] = useState('');
  const [settingsId, setSettingsId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loading state for initial fetch
  const [selectedOption, setSelectedOption] = useState(responseType); // Track selected option

  useEffect(() => {
    const fetchSettings = async () => {
      if (user && user.id) {
        try {
          const response = await axiosInstance.get(`/api/settings/${user.id}/`);
          const settings = response.data;

          if (settings) {
            const { id, response_type, instructions: savedInstructions } = settings;
            setSettingsId(id);
            setSettingResponseType(response_type || 'accurate');
            setInstructions(savedInstructions || '');
          }
        } catch (error) {
          console.error('Error fetching settings:', error.message || error);
        } finally {
          setIsLoading(false); // Stop loading after fetching
        }
      } else {
        setIsLoading(false); // Stop loading when user is unavailable
      }
    };

    fetchSettings();
  }, [user]);

  const handleInputChange = (value, type) => {
    if (type === 'instructions') {
      setInstructions(value);
    } else if (type === 'responseType') {
      setResponseType(value);
    }
  };

  const updateSettings = async (e) => {
    e.preventDefault();

    const data = {
      response_type: responseType,
      instructions: instructions,
    };

    setIsSaving(true);

    try {
      await axiosInstance.patch(`/api/settings/${settingsId}/`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsSaving(false);
      toast.success('Settings updated successfully');
      setModalOpen(false);
      setSettingResponseType(responseType);
    } catch (error) {
      console.error('An error occurred while updating settings:', error.message || error);
      setIsSaving(false);
      toast.error('Failed to update settings');
      setModalOpen(false);
    }
  };

  const handleDropdownSelect = (value) => {
    handleInputChange(value, 'responseType');
    setSelectedOption(value); // Update selected option
    setDropdownOpen(false);
  };

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <form className="flex flex-col gap-4">
        <div>
          <label className="block text-black font-medium mb-2 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] dark:text-white">
            Response Type
          </label>
          <div className="relative">
            <div className="flex">
              <button
                type="button"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="w-full flex 2xl:text-[16px] xl:text-[15px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] items-center justify-between text-black border border-gray-300 rounded-md p-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-black dark:text-white"
              >
                <span>{responseType.charAt(0).toUpperCase() + responseType.slice(1)}</span>
                <TiArrowSortedDown className="text-black 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px] dark:text-white" />
              </button>
            </div>

            {dropdownOpen && (
              <div className="absolute w-full text-black mt-1 bg-white border border-gray-300 rounded-md shadow-lg z-10 2xl:text-[15px] xl:text-[14px] lg:text-[12px] md:text-[10px] sm:text-[8px] text-[7px] dark:bg-black">
                <div
                  onClick={() => handleDropdownSelect('accurate')}
                  className={`p-2 cursor-pointer hover:bg-[#FF00000D] ${
                    selectedOption === 'accurate' ? 'text-red-button dark:text-red-button' : ''
                  } dark:text-white`}
                >
                  Accurate
                </div>
                <div
                  onClick={() => handleDropdownSelect('creative')}
                  className={`p-2 cursor-pointer hover:bg-[#FF00000D] ${
                    selectedOption === 'creative' ? 'text-red-button dark:text-red-button' : ''
                  } dark:text-white`}
                >
                  Creative
                </div>
                <div
                  onClick={() => handleDropdownSelect('balanced')}
                  className={`p-2 cursor-pointer hover:bg-[#FF00000D] ${
                    selectedOption === 'balanced' ? 'text-red-button dark:text-red-button' : ''
                  } dark:text-white`}
                >
                  Balanced
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <label className="block text-black 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] font-medium mb-2 dark:text-white">
            Instructions
          </label>
          <div className="w-full h-auto border border-gray-300 bg-white rounded-md p-1 dark:bg-black">
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div className="loading-spinner"></div>
              </div>
            ) : (
              <textarea
                value={instructions}
                onChange={(e) => handleInputChange(e.target.value, 'instructions')}
                rows={4}
                className="w-full 2xl:text-[16px] xl:text-[15px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] text-black border-none bg-transparent focus:outline-none resize-none dark:bg-black dark:text-white"
              />
            )}
          </div>
        </div>

        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setModalOpen(false)}
            className="flex bg-background-gray text-close-button py-2 px-3 rounded-md 2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[10px]"
          >
            Close
          </button>
          <button
            type="button"
            onClick={updateSettings}
            className={`flex py-2 px-3 rounded-md 2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[10px] ${
              isSaving ? 'bg-success text-white cursor-not-allowed' : 'bg-red-button text-white'
            }`}
          >
            {isSaving ? 'Saving...' : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Setting;

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useChatContext } from '../context/chatContext';
import { useParams, useNavigate } from 'react-router-dom';
import { flushSync } from 'react-dom';
import { toast, Toaster } from 'react-hot-toast';
import { usePDF } from 'react-to-pdf';
import ChatMessage from './ChatMessage';
import SideBar from './SideBar';
import useAxiosInstance from '../utils/axiosInstance.js';
import Header from './Header';
import WelcomeModal from './WelcomeModal';
import SuggestionGrid from './SuggestionGrid';
import HistoryGrid from './HistoryGrid';
import ThreadPanel from './ThreadPanel';
import ChatInput from './ChatInput';
import ChatMenu from './ChatMenu';
import FilesSection from './FilesSection';
import AnalysisComponent from './AnalysisComponent';
import FeatureUnavailable from './FeatureUnavailable';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as PanelIcon } from '../Svg/panel_open.svg';
import clsx from 'clsx';

const ChatView = () => {
  const messagesEndRef = useRef();
  const chatContainerRef = useRef();
  const fileInputRef = useRef(null);
  const axiosInstance = useAxiosInstance();
  const inputRef = useRef();
  const [formValue, setFormValue] = useState('');
  const {
    messages,
    setMessages,
    defaultThread,
    setDefaultThread,
    isGeneratingAnswer,
    setIsGeneratingAnswer,
    showWelcomeModal,
    setShowWelcomeModal,
    showSuggestionGrid,
    setShowSuggestionGrid,
    showHistoryGrid,
    setShowHistoryGrid,
    showSuggestionGridAfterSpinner,
    setShowSuggestionGridAfterSpinner,
    setDarkMode,
    handleDeleteClick,
    user,
    showPanelIcon,
    isLoadingThreads,
    lastVisitedThreadId,
    setLastVisitedThreadId,
    setChats,
    setHistoryItems,
    handleNewThread,
    chats,
    isLoadingChats,
    setIsLoadingChats,
    searchQuery,
    setSearchResultExists,
    fetchThreads,
  } = useChatContext();
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [uploadError, setUploadError] = useState(null);
  const [isUserNearBottom, setIsUserNearBottom] = useState(true);
  const { threadId } = useParams();
  const { toPDF, targetRef } = usePDF({ filename: `${threadId || 'chat_history'}.pdf` });
  const navigate = useNavigate();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const [showHistoryPanel, setShowHistoryPanel] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [isDocumentUploadEnabled, setIsDocumentUploadEnabled] = useState(true);
  const isThreadSelected = threadId && messages.length > 0;
  const [showFilesSection, setShowFilesSection] = useState(false);
  const [showFeatureUnavailable, setShowFeatureUnavailable] = useState(false);
  const [showChatInput, setShowChatInput] = useState(true);

  const [analysisStep, setAnalysisStep] = useState(-1);
  const currentChat = chats.find((chat) => chat.thread_id === defaultThread); // Get the current chat based on defaultThread
  const handleImageIconClick = () => {
    setShowFilesSection(true);
    setShowChatInput(false);
    setShowFeatureUnavailable(false);
  };

  const handleChatIconClick = () => {
    console.log('Chat icon clicked. Showing SuggestionGrid.'); // Debug log
    setShowFilesSection(false);
    setShowChatInput(true);
    setShowFeatureUnavailable(false);
    setShowSuggestionGrid(true); // Ensure this sets to true
  };

  const fetchChats = async (threadId) => {
    setIsLoadingChats(true); // Show spinner while loading
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/threads/${threadId}`,
      );
      console.log('API response (raw):', response.data);
      if (response.data && Array.isArray(response.data.chats)) {
        setMessages(response.data.chats);
      }
    } catch (error) {
      console.error('Error fetching chats:', error.response?.data || error.message);
    } finally {
      setIsLoadingChats(false); // Hide spinner when done
    }
  };

  const fetchThreadsInternal = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/threads/');
      const sortedThreads = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      setChats(sortedThreads);
      setHistoryItems(sortedThreads);
      if (sortedThreads.length > 0 && !defaultThread) {
        setDefaultThread(sortedThreads[0].thread_id);
      }
    } catch (error) {
      console.error('Error fetching threads:', error.response?.data || error.message);
    }
  }, [axiosInstance, defaultThread]);

  const handleNewChat = async () => {
    try {
      const modelType = 'azure';
      const threadName = `New Chat ${uuidv4().substr(0, 8)}`;

      const response = await axiosInstance.post('/api/threads/', {
        thread_name: threadName,
        model_name: modelType,
      });

      const newChat = response.data;
      setLastVisitedThreadId(newChat.thread_id);
      return newChat.thread_id;
    } catch (error) {
      console.error('Error creating thread:', error.message);
    }
  };

  useEffect(() => {
    if (messages.length === 0) {
      if (showWelcomeModal === false && showHistoryGrid == false) {
        setShowSuggestionGridAfterSpinner(true);
        setShowSuggestionGrid(true);
      }
    } else {
      setShowSuggestionGridAfterSpinner(false);
      setShowSuggestionGrid(false);
    }
  });

  useEffect(() => {
    // Only show SuggestionGrid if explicitly triggered (e.g., chat icon click)
    if (messages.length === 0 && !isGeneratingAnswer) {
      setShowSuggestionGridAfterSpinner(false); // Reset to prevent accidental flicker
      setShowSuggestionGrid(false);
    }
  }, [messages, isGeneratingAnswer]);

  // Navigate to specific thread and dismiss HistoryGrid
  const handleChatClick = useCallback(
    async (threadId) => {
      setDefaultThread(threadId);
      setShowHistoryGrid(false); // Dismiss the HistoryGrid
      setShowSuggestionGrid(false); // Dismiss the SuggestionGrid
      const data = {
        thread: threadId,
        user: user.id,
      };

      try {
        const response = await axiosInstance.post('/api/last-accessed-threads/', data, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        console.log('Successfully updated last accessed thread:', response.data);

        // Optionally navigate to the thread after successful API call
        navigate(`/threads/${threadId}`);
      } catch (error) {
        console.error('Error updating last accessed thread:', error);
      }
      navigate(`/threads/${threadId}`);
    },
    [navigate, setDefaultThread, setShowHistoryGrid, setShowSuggestionGrid],
  );

  // Toggle canvas width for history panel
  const toggleCanvas = (width) => {
    setCanvasWidth(width);
    setShowHistoryPanel((prev) => !prev);
  };

  const checkIfUserIsNearBottom = () => {
    const container = chatContainerRef.current;
    const isNearBottom =
      container.scrollHeight - container.scrollTop <= container.clientHeight + 100;
    setIsUserNearBottom(isNearBottom);
  };

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkIfUserIsNearBottom);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', checkIfUserIsNearBottom);
      }
    };
  }, []);

  useEffect(() => {
    if (isGeneratingAnswer) {
      ensureScrollToBottom();
    }
  }, [isGeneratingAnswer]);

  useEffect(() => {
    if (messages.length > 0) {
      const foundResult = messages.some((message) =>
        message.text.toLowerCase().includes(searchQuery.trim().toLowerCase()),
      );
      if (foundResult) {
        setSearchResultExists(true);
      } else {
        setSearchResultExists(false);
      }
    }
  }, [messages, searchQuery]);

  const updateMessage = (text, isAI = false) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: Date.now(),
        createdAt: new Date().toISOString(),
        text,
        ai: isAI,
      },
    ]);
  };

  const sendMessageToAPI = async (messageToSend, threadId) => {
    try {
      setIsGeneratingAnswer(true);
      setShowChatInput(false); // Hide the chat input when sending the message
      const accessTokenKey = Object.keys(sessionStorage).find((key) => key.includes('accesstoken'));
      const sessionData = JSON.parse(sessionStorage.getItem(accessTokenKey));
      const bearerToken = sessionData;
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/stream/?message=${encodeURIComponent(
        messageToSend,
      )}&thread_id=${threadId}&document_search=${isDocumentUploadEnabled}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'text/plain',
        },
        redirect: 'follow',
      });

      if (response.status === 403) {
        console.error('Access forbidden. Navigating to login.');
        navigate('/login');
        return;
      }

      if (!response.ok) {
        throw new Error(`Failed to get response from the API: ${response.status}`);
      }

      setShowWelcomeModal(false);
      setShowSuggestionGrid(false);
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      // Start with an empty response block
      flushSync(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: Date.now(),
            createdAt: new Date().toISOString(),
            text: '',
            ai: true,
          },
        ]);
      });
      // setTimeout(() => scrollToBottom(true), 100); // Allow DOM updates before scrolling (previous example)
      ensureScrollToBottom();

      const streamMessages = async () => {
        let done = false;
        while (!done) {
          const { done: isDone, value } = await reader.read();
          done = isDone;
          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            const chat_id = JSON.parse(chunk?.split('\n')[0])?.chat_uuid;
            accumulatedResponse += chunk; // Accumulate the response

            flushSync(() => {
              setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                updatedMessages[updatedMessages.length - 1].text = accumulatedResponse;
                if (chat_id) {
                  updatedMessages[updatedMessages.length - 1].chat_id = chat_id;
                }
                return updatedMessages;
              });
            });
          }
        }
      };

      await streamMessages();
      if (messages.length === 0) {
        fetchThreads();
      }
    } catch (error) {
      console.error('Error sending message to API:', error.message);
      updateMessage(error.message, true);
    } finally {
      setIsGeneratingAnswer(false);
      setShowChatInput(true); // Show the chat input again after response generation
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault?.(); // Handle fake events
    const inputValue = e.target?.value || formValue; // Use formValue or the suggestion
    if (!inputValue.trim() && attachedFiles.length === 0) return;
    // Hide SuggestionGrid immediately on message submission
    setShowSuggestionGrid(false);
    setShowSuggestionGridAfterSpinner(false);
    if (!threadId) {
      setShowWelcomeModal(false);
      setShowHistoryGrid(false);
      var new_chat_id = lastVisitedThreadId;
      new_chat_id = await handleNewChat();
    }

    const cleanPrompt = inputValue.trim().replace(/\n/g, '\\n'); // Preserve line breaks

    setFormValue(''); // Clear the input field
    updateMessage(cleanPrompt, false);

    // Hide ChatInput while waiting for AI response
    setShowChatInput(false);

    // Start the analysis sequence
    setAnalysisStep(0); // Start with analyzing data

    // Simulate the analysis steps
    await new Promise((resolve) => {
      setTimeout(() => {
        setAnalysisStep(1); // Analyzing complete
        resolve();
      }, 800); // Reduced delay for analyzing time
    });

    await new Promise((resolve) => {
      setTimeout(() => {
        setAnalysisStep(2); // Generating response
        resolve();
      }, 800); // Reduced delay for generating time
    });

    // Introduce a delay before showing the checkmark
    await new Promise((resolve) => {
      setTimeout(() => {
        setAnalysisStep(3); // Show generating response with checkmark
        resolve();
      }, 800); // Reduced delay for showing checkmark
    });

    // Now set to typing
    await new Promise((resolve) => {
      setTimeout(() => {
        setAnalysisStep(4); // Show typing animation
        resolve();
      }, 800); // Reduced delay for typing animation
    });
    if (threadId) {
      await sendMessageToAPI(cleanPrompt, threadId); // Send the message to the API
    } else {
      await sendMessageToAPI(cleanPrompt, new_chat_id);
      navigate(`/threads/${new_chat_id}`);
    }

    // Show ChatInput again after AI response is completed
    setShowChatInput(true);
  };

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(
      (file) =>
        file.type === 'application/pdf' ||
        file.type === 'application/docx' ||
        file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    );
    const oversizedFiles = files.filter((file) => file.size > 10 * 1024 * 1024);

    if (validFiles.length === files.length && oversizedFiles.length === 0) {
      setUploadError(null);
      setAttachedFiles((prevFiles) => [...prevFiles, ...validFiles]);
      for (const file of validFiles) {
        const formData = new FormData();
        formData.append('files', file);
        formData.append('content_type', file.type);

        try {
          const response = await axiosInstance.post('/api/documents/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-CSRFTOKEN': '3iPSBHamCcYXvaWfR5EzNiJHOVKr4HR6jhQ8Zdzo63k3ZfJ84l2TALJW2KknZQwk',
            },
          });
          console.log('File uploaded successfully:', response.data);
          toast.success(`${file.name} uploaded successfully!`);
        } catch (error) {
          console.error('Error uploading file:', error.response?.data || error.message);
          toast.error(`Failed to upload ${file.name}. Please try again.`);
        }
      }
      fileInputRef.current.value = null;
    } else {
      if (validFiles.length !== files.length) {
        toast.error('Only .pdf & .docx,  files can be uploaded');
      }
      if (oversizedFiles.length > 0) {
        toast.error('File size exceeds the maximum limit of 10MB');
      }
      setTimeout(() => setUploadError(null), 5000);
    }
  };

  const handleEmojiClick = (emojiObject) => {
    // Save the current cursor position
    const cursorPosition = inputRef.current.selectionStart;

    // Append the emoji to the form value
    const newValue =
      formValue.slice(0, cursorPosition) + emojiObject.emoji + formValue.slice(cursorPosition);

    // Update the input value with the new string containing the emoji
    setFormValue(newValue);

    // Restore the cursor position after the emoji is added
    setTimeout(() => {
      inputRef.current.setSelectionRange(
        cursorPosition + emojiObject.emoji.length,
        cursorPosition + emojiObject.emoji.length,
      );
      inputRef.current.focus(); // Ensure the input stays focused
    }, 0);

    // Close the emoji picker after selection
    setShowEmojiPicker(false);
  };

  // Close emoji picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside the emoji picker
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  //this function will keeps scrolling until the bottom is reached
  //by default the scroll behaviour is smooth by force = true it will scroll to the bottom immediately
  const ensureScrollToBottom = (force = false) => {
    const endRef = messagesEndRef.current;
    const container = endRef?.parentElement; // Get the scrolling container

    if (!container) return;

    const scrollToEnd = () => {
      requestAnimationFrame(() => {
        endRef.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
          const atBottom =
            container.scrollHeight - container.scrollTop <= container.clientHeight + 10;

          if (!atBottom) {
            scrollToEnd();
          }
        }, 100);
      });
    };

    if (force) {
      container.scrollTop = container.scrollHeight;
    }

    scrollToEnd(); // Start scrolling process
  };

  useEffect(() => {
    ensureScrollToBottom();
  }, [messages]);

  useEffect(() => {
    // Reset state when no threadId is available
    if (!threadId) {
      setMessages([]);
      setShowHistoryGrid(true);
      setShowWelcomeModal(true);
      setShowSuggestionGrid(false);
      // fetchLastVisitedThreadId();
      return;
    }

    // Clear messages and fetch chats for the selected thread
    setMessages([]);
    fetchChats(threadId);
    fetchThreadsInternal();

    // Ensure grids and modals are appropriately dismissed
    setShowHistoryGrid(false);
    setShowWelcomeModal(false);
    setShowSuggestionGrid(true); // Ensure SuggestionGrid is dismissed by default

    // Show WelcomeModal or SuggestionGrid based on thread state
    const welcomeShownKey = `welcomeShown_${threadId}`;
    if (!localStorage.getItem(welcomeShownKey)) {
      setShowSuggestionGrid(true); // Show SuggestionGrid only for new threads
      localStorage.setItem(welcomeShownKey, 'true');
    }

    // Focus the input field
    inputRef.current?.focus();
  }, [threadId]);

  useEffect(() => {
    if (!isGeneratingAnswer) {
      inputRef.current?.focus();
    }
  }, [isGeneratingAnswer]);

  useEffect(() => {
    if (!isGeneratingAnswer) {
      setAnalysisStep(-1); // Show typing animation when generating answer
    }
  }, [isGeneratingAnswer]);

  // On component mount, apply the stored mode
  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedMode);
    document.documentElement.classList.toggle('dark', savedMode);
  }, []);

  const hideChatInput = () => {
    setShowChatInput(false);
  };

  return (
    <div>
      <Header />
      <Toaster position="top-right" reverseOrder={false} />

      <div className="flex bg-background-gray 2xl:h-[89vh] xl:h-[85vh] lg:h-[91vh] md:h-[92vh] sm:h-[93vh] h-[93vh] 2xl:mr-16 xl:mr-14 lg:mr-12 md:mr-10 sm:mr-8 mr-6 dark:bg-black dark:text-white">
        <SideBar
          showHistoryPanel={showHistoryPanel}
          setShowHistoryPanel={setShowHistoryPanel}
          setCanvasWidth={setCanvasWidth}
          setShowFeatureUnavailable={setShowFeatureUnavailable}
        />
        <div
          className={`chatview w-full 2xl:h-[88vh] xl:h-[85vh] lg:h-[90vh] md:h-[92vh] sm:h-[92vh] h-[90vh] bg-white rounded-[24px] transition-[margin] duration-500 dark:bg-[#1B1C1E] ${
            showHistoryPanel
              ? 'mr-[150px] sm:mr-[200px] md:mr-[240px] lg:mr-[300px] xl:mr-[320px] 2xl:mr-[350px]'
              : 'mr-0'
          }`}
        >
          {/* Top Bar or Menu Area */}
          <div
            className={` mx-auto ${
              showHistoryPanel
                ? '2xl:w-[896px] xl:w-[700px] lg:w-[490px] md:w-[350px] sm:w-[300px] w-[250px]'
                : '2xl:w-[896px] xl:w-[800px] lg:w-[610px] md:w-[570px] sm:w-[450px] w-[270px]'
            }`}
          >
            <div className={`flex items-center justify-between p-3 dark:bg-[#1B1C1E] ml-[35px]`}>
              {/* Menu Options */}
              {isThreadSelected && !showFeatureUnavailable && (
                <ChatMenu
                  onImageIconClick={handleImageIconClick}
                  onChatIconClick={handleChatIconClick}
                  currentChat={currentChat}
                  showHistoryPanel={showHistoryPanel}
                  toPDF={toPDF}
                />
              )}
            </div>
          </div>
          {/* Background image */}
          <div className="absolute 2xl:top-[300px] 2xl:left-[300px] xl:top-[200px] xl:left-[200px] lg:top-[150px] lg:left-[150px] md:top-[100px] md:left-[120px] sm:top-[100px] sm:left-[100px] top-[90px] left-[90px] transform -translate-x-1/2 -translate-y-1/2 2xl:w-[622px] 2xl:h-[600px] xl:w-[422px] xl:h-[400px] lg:w-[322px] lg:h-[300px] md:w-[250px] md:h-[220px] sm:w-[220px] sm:h-[200px] w-[200px] h-[180px] bg-[url('/src/assets/vector.png')] bg-contain bg-no-repeat opacity-100 z-0"></div>
          {showFeatureUnavailable ? (
            <div className=" flex justify-center items-center h-full">
              <FeatureUnavailable />
            </div>
          ) : showFilesSection ? (
            <div className="flex justify-center items-center h-full mb-48">
              <FilesSection />
            </div>
          ) : (
            <div className="h-full w-full overflow-y-auto" ref={chatContainerRef}>
              <main
                className={`chatview__chatarea h-full flex flex-col ${
                  showHistoryPanel
                    ? '2xl:w-[896px] xl:w-[700px] lg:w-[490px] md:w-[350px] sm:w-[300px] w-[250px]'
                    : '2xl:w-[896px] xl:w-[800px] lg:w-[610px] md:w-[570px] sm:w-[450px] w-[270px]'
                } mx-auto relative pt-10 overflow-y-auto ${clsx(
                  messages.length === 0 && !isGeneratingAnswer && ' items-center justify-center ',
                )}`}
                ref={chatContainerRef}
              >
                {/* Show Welcome Modal */}
                {showWelcomeModal && (
                  <WelcomeModal
                    onClose={() => setShowWelcomeModal(false)}
                    user={user}
                    showHistoryPanel={showHistoryPanel}
                  />
                )}

                {/* Show Spinner for Loading State */}
                {isLoadingChats && (
                  <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-10 dark:bg-black dark:bg-opacity-80">
                    <div className="loading-spinner"></div>
                  </div>
                )}
                {/* Show HistoryGrid */}
                {showHistoryGrid && (
                  <HistoryGrid
                    showHistoryPanel={showHistoryPanel}
                    handleChatClick={handleChatClick}
                  />
                )}

                {/* Show SuggestionGrid */}
                {showSuggestionGridAfterSpinner && (
                  <SuggestionGrid
                    showHistoryPanel={showHistoryPanel}
                    sendMessage={(fakeEvent) => {
                      const suggestion = fakeEvent.target.value;
                      updateMessage(suggestion, false); // Update the chat with the suggestion
                      sendMessageToAPI(suggestion, threadId); // Trigger the message sending logic
                    }}
                    setAnalysisStep={setAnalysisStep}
                    hideChatInput={hideChatInput}
                  />
                )}

                {/* Display Messages */}
                <div ref={targetRef}>
                  {threadId && messages.length > 0
                    ? messages.map((message, index) => (
                        <ChatMessage
                          key={message.id || index}
                          message={message}
                          chatContainerRef={chatContainerRef}
                          isLastMessage={index === messages.length - 1 && message.ai}
                          showHistoryPanel={showHistoryPanel}
                          setFormValue={setFormValue}
                          inputRef={inputRef}
                          className={` ${
                            showHistoryPanel
                              ? '2xl:w-[896px] xl:w-[700px] lg:w-[500px] md:w-[350px] sm:w-[330px] w-[250px]'
                              : '2xl:w-[896px] xl:w-[800px] lg:w-[650px] md:w-[570px] sm:w-[460px] w-[300px]'
                          }`}
                        />
                      ))
                    : // Placeholder when no messages are present
                      !isLoadingChats &&
                      !isGeneratingAnswer && <p className="text-center text-gray-500 mt-4"></p>}
                </div>
                <div className="animation-container" style={{ marginBottom: '50px' }}>
                  {analysisStep >= 0 && <AnalysisComponent step={analysisStep} />}
                </div>
                {/* Scroll Anchor */}
                <span ref={messagesEndRef}></span>
              </main>
            </div>
          )}
          {!showFeatureUnavailable && showChatInput && (
            <ChatInput
              formValue={formValue}
              setFormValue={setFormValue}
              sendMessage={sendMessage}
              inputRef={inputRef}
              fileInputRef={fileInputRef}
              handleFileUpload={handleFileUpload}
              showHistoryPanel={showHistoryPanel}
              isGeneratingAnswer={isGeneratingAnswer}
              defaultThread={defaultThread}
              uploadError={uploadError}
              showEmojiPicker={showEmojiPicker}
              setShowEmojiPicker={setShowEmojiPicker}
              handleEmojiClick={handleEmojiClick}
              emojiPickerRef={emojiPickerRef}
              setIsDocumentUploadEnabled={setIsDocumentUploadEnabled}
              isDocumentUploadEnabled={isDocumentUploadEnabled}
            />
          )}
        </div>

        <div className="absolute 2xl:top-[88px] xl:top-[80px] lg:top-[75px] md:top-[70px] sm:top-[65px] top-[60px] 2xl:right-5 xl:right-4 lg:right-3 md:right-2 sm:right-1.5 right-1 text-close-button cursor-pointer 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px] dark:text-gray-icon">
          <PanelIcon
            width="22"
            height="22"
            className="fill-icon-light-fill dark:fill-white"
            onClick={() => toggleCanvas(350)}
            role="img"
            aria-label="Panel Icon"
          />
        </div>
        {/* Canvas of Threads */}
        <ThreadPanel
          showHistoryPanel={showHistoryPanel}
          toggleCanvas={toggleCanvas}
          isLoadingThreads={isLoadingThreads}
          chats={chats}
          handleDeleteClick={handleDeleteClick}
          handleChatClick={handleChatClick}
        />
      </div>
      <div
        className={`flex justify-center 2xl:ml-[40px] xl:ml-[60px] lg:ml-[70px] md:ml-[60px] sm:ml-[60px] ml-[50px] ${
          showHistoryPanel
            ? '2xl:mr-[350px] xl:mr-[300px] lg:mr-[200px] md:mr-[200px] sm:mr-[170px] mr-[180px]'
            : ''
        } transition-[width,margin] duration-1000`}
      >
        <h1
          className={`2xl:w-[848px] xl:w-[780px] lg:w-[760px] md:w-[560px] sm:w-[460px] w-[260px] ${
            showHistoryPanel ? 'text-left' : 'text-left'
          } transition-colors duration-150 dark:text-gray-icon 2xl:text-[12px] xl:text-[10px] lg:text-[8px] md:text-[6px] sm:text-[5px] text-[4px] py-2`}
        >
          Disclaimer: Elysium Workspace chats are private and not used to improve AI models. Elysium
          AI may occasionally provide inaccurate or incomplete
          <br />
          information; please verify critical details independently.
        </h1>
      </div>
    </div>
  );
};

export default ChatView;

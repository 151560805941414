import React from 'react';
import { MdClose } from 'react-icons/md';
import { useChatContext } from '../context/chatContext';

const WelcomeModal = ({ onClose, user, showHistoryPanel }) => {
  const { greetingMessage } = useChatContext();
  return (
    <div
      className={`welcome-modal bg-welcome p-4 rounded-lg shadow-sm mx-auto  dark:bg-black ${
        showHistoryPanel
          ? '2xl:w-[870px] 2xl:h-[156px] xl:w-[670px] xl:h-[180px] lg:w-[460px] lg:h-[150px] md:w-[300px] md:h-[150px] sm:w-[250px] sm:h-[150px] w-[200px] h-[140px]'
          : '2xl:w-[870px] 2xl:h-[156px] xl:w-[770px] xl:h-[145px] lg:w-[580px] lg:h-[150px] md:w-[500px] md:h-[135px] sm:w-[420px] sm:h-[125px] w-[240px] h-[120px]'
      } transition-all duration-300`}
    >
      <div className="flex justify-between items-center mb-4  ">
        <h2
          className={`text-black font-semibold 2xl:text-[20px] xl:text-[18px] lg:text-[16px] md:text-[15px] sm:text-[20px] sm:font-700 text-[11px] dark:text-white`}
        >
          Welcome back, {user.username}!
        </h2>
        <MdClose
          className="text-close-button cursor-pointer  2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px] dark:text-gray-icon"
          onClick={onClose}
        />
      </div>
      <p
        className={`text-black mb-3 2xl:text-[16px] xl:text-[14px] lg:text-[12px] md:text-[10px] sm:text-[16px] sm:font-400 text-[7px] dark:text-white`}
      >
        {greetingMessage
          ? greetingMessage
          : 'It’s great to see you back! If you need a quick refresher on anything or assistance getting up to speed, I’m here to help. Let’s make today productive – feel free to dive into your tasks or reach out for any support.'}
      </p>
      <p
        className={`text-black mb-4 2xl:text-[16px] xl:text-[14px] lg:text-[12px] md:text-[10px] sm:text-[16px] sm:font-400 text-[7px] dark:text-white`}
      >
        Let’s get back on track together! 😊
      </p>
    </div>
  );
};

export default WelcomeModal;

import React from 'react';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from '../Svg/close.svg';

const Modal = ({
  title,
  modalOpen,
  setModalOpen,
  children,
  size = 'default',
  className,
  style,
}) => {
  if (!modalOpen) return null;

  // Define size-based classes
  const sizeClasses = {
    smallDefault:
      '2xl:w-[360px] 2xl:h-[204px] xl:w-[340px] xl:h-[190px] lg:w-[330px] lg:h-[180px] md:w-[310px] md:h-[170px] sm:w-[290px] sm:h-[160px] w-[260px] h-[160px] 2xl:p-6 xl:p-3 lg:p-3 md:p-3 sm:p-2.5 p-4',
    small:
      '2xl:w-[360px] 2xl:h-[204px] xl:w-[340px] xl:h-[230px] lg:w-[330px] lg:h-[180px] md:w-[310px] md:h-[170px] sm:w-[290px] sm:h-[160px] w-[260px] h-[160px] 2xl:p-6 xl:p-3 lg:p-3 md:p-3 sm:p-2.5 p-4',
    medium:
      '2xl:w-[480px] 2xl:h-[388px] xl:w-[460px] xl:h-[375px] lg:w-[450px] lg:h-[360px] md:w-[400px] md:h-[330px] sm:w-[360px] sm:h-[310px] w-[300px] h-[280px] 2xl:p-6 xl:p-6 lg:p-5 md:p-4 sm:p-3 p-4',
    default: 'w-[360px]', // Fallback size if none is provided
  };

  return (
    <div className="fixed inset-0 z-50 bg-gray-800 bg-opacity-75">
      <div
        className={clsx(
          'bg-white rounded-lg dark:bg-black',
          sizeClasses[size], // Dynamically apply size classes
          className, // Apply the custom className
        )}
        style={
          size === 'small'
            ? { position: 'absolute', ...style } // Dynamic positioning for small size
            : { position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } // Centered positioning for others
        }
      >
        <div className="flex justify-between items-center mb-4 dark:text-white">
          <h2 className="2xl:text-[18px] xl:text-[18px] lg:text-[17px] md:text-[16px] sm:text-[15px] text-[14px] text-black font-semibold dark:text-white">
            {title}
          </h2>
          <button
            className="text-close-button 2xl:text-[24px] xl:text-[23px] lg:text-[22px] md:text-[20px] sm:text-[18px] text-[16px] dark:text-white"
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon className="fill-icon-light-fill dark:fill-white" />
          </button>
        </div>
        <div className="px-4 py-2">{children}</div>
      </div>
    </div>
  );
};

export default Modal;

import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import CopyIcon from '../Svg/copy.svg';
import DeleteIcon from '../Svg/delete.svg';
import { toast, Toaster } from 'react-hot-toast';

const ShareChat = ({ isOpen, onClose }) => {
  const [shareableLink] = useState('https://elysium.chat/u7EHC986BGY');
  const [emailOrName, setEmailOrName] = useState('');
  const [sharedUsers, setSharedUsers] = useState([]);
  const [registeredUsers, setRegisteredUsers] = useState([]);

  // Fetch registered users when the component mounts
  // useEffect(() => {
  //   const fetchRegisteredUsers = async () => {
  //     try {
  //       const response = await fetch('/api/registered-users'); // Replace with your actual endpoint
  //       const data = await response.json();
  //       setRegisteredUsers(data); // Assuming `data` is an array of user objects
  //     } catch (error) {
  //       console.error('Error fetching registered users:', error);
  //     }
  //   };

  //   fetchRegisteredUsers();
  // }, []);

  const handleShare = () => {
    if (!emailOrName.trim()) {
      console.log('Please enter an email or name to share.');
      return;
    }

    // Check if the email or name exists in the registered users list
    const matchedUser = registeredUsers.find(
      (user) =>
        user.email.toLowerCase() === emailOrName.toLowerCase() ||
        user.name.toLowerCase() === emailOrName.toLowerCase(),
    );

    if (!matchedUser) {
      alert('This user is not registered.');
      return;
    }

    if (sharedUsers.some((user) => user.email === matchedUser.email)) {
      alert('This user has already been added.');
      return;
    }

    // Add the matched user to the shared users list
    setSharedUsers((prev) => [...prev, matchedUser]);
    setEmailOrName('');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-[400px] p-6 dark:bg-black">
        <Toaster />
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-black text-lg font-semibold dark:text-white">Share Chat</h2>
          <MdClose
            className="text-close-button text-xl cursor-pointer dark:text-white"
            onClick={onClose}
          />
        </div>

        {/* Share Read-Only Link */}
        <div className="mb-5">
          <div className="flex justify-between items-center">
            <p className="text-black text-md font-medium dark:text-white">Share read-only link</p>
          </div>
          <p className="text-close-button mb-4 dark:text-white">
            Anyone with the link can view this file, but won’t be able to edit it.
          </p>
          <div className="flex items-center border rounded-lg px-3 py-2 bg-[#1414141A]">
            <input
              type="text"
              value={shareableLink}
              readOnly
              className="flex-grow text-black bg-transparent focus:outline-none dark:text-white"
            />
            <img
              src={CopyIcon}
              alt="Copy"
              width="20"
              height="20"
              onClick={() => {
                navigator.clipboard.writeText(shareableLink);
                toast.success('Link copied to clipboard!', {
                  position: 'top-center',
                  style: {
                    backgroundColor: '#4BB543',
                    color: '#fff',
                    fontSize: '14px',
                  },
                });
              }}
              className="ml-2 cursor-pointer dark:text-white"
            />
          </div>
        </div>
        <hr className="border-[#F1F1F1] mb-4" />

        {/* Share with Team Member */}
        <div>
          <p className="text-black font-medium dark:text-white">Share with team member</p>
          <p className="text-close-button mb-2 dark:text-white">
            Anyone with the link can view this file.
          </p>
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Share by email or name"
              value={emailOrName}
              onChange={(e) => setEmailOrName(e.target.value)}
              className="flex-grow border text-black bg-transparent rounded-lg px-3 py-2 focus:outline-none dark:text-white"
            />
            <button
              onClick={handleShare}
              disabled={!emailOrName.trim()}
              className={`ml-2 rounded-lg px-4 py-2 font-medium ${
                !emailOrName.trim()
                  ? 'bg-red-button opacity-15 text-white cursor-not-allowed'
                  : 'bg-red-button text-white'
              }`}
            >
              Share
            </button>
          </div>
        </div>

        {/* Shared Users Display */}
        <div className="mt-4">
          {sharedUsers.map((user, index) => (
            <div key={index} className="flex items-center justify-between p-2 rounded mb-2">
              <div className="flex items-center">
                <img
                  src={user.image || 'default-profile-image-url'}
                  alt={user.name}
                  className="w-8 h-8 rounded-full mr-2"
                />
                <span className="text-sm text-black dark:text-white">{user.email}</span>
              </div>
              <button
                type="button"
                onClick={() => setSharedUsers((prev) => prev.filter((_, i) => i !== index))}
                className="text-red-500 hover:text-red-700"
              >
                <img
                  src={DeleteIcon}
                  alt="Delete"
                  width="20"
                  height="20"
                  className="mr-2 text-close-button dark:text-white"
                />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShareChat;

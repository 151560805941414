import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosInstance from '../utils/axiosInstance';
import DeleteModal from '../components/DeleteModal'; // Import the modular DeleteModal component

const ChatContext = createContext();

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChatContext must be used within a ChatContextProvider');
  }
  return context;
};

export const ChatContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [defaultThread, setDefaultThread] = useState(null);
  const [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
  const [user, setUser] = useState({ username: 'User', title: 'Member', id: null, oid: null });
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [showSuggestionGrid, setShowSuggestionGrid] = useState(false);
  const [showHistoryGrid, setShowHistoryGrid] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);
  const [chats, setChats] = useState([]); // chats state
  const [historyItems, setHistoryItems] = useState([]);
  const [isLoadingThreads, setIsLoadingThreads] = useState(false);
  const [isCreatingThread, setIsCreatingThread] = useState(false);
  const [settingResponseType, setSettingResponseType] = useState('accurate');
  const [showSuggestionGridAfterSpinner, setShowSuggestionGridAfterSpinner] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [showPanelIcon, setShowPanelIcon] = useState(false);
  const [lastVisitedThreadId, setLastVisitedThreadId] = useState('');
  const [formValue, setFormValue] = useState(null);
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // New state for search input
  const [searchResultExists, setSearchResultExists] = useState(false); // New state for search input

  const [profilePicture, setProfilePicture] = useState(null);
  const [greetingMessage, setGreetingMessage] = useState(null);
  const [userStatusDescription, setUserStatusDescription] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const axiosInstance = useAxiosInstance();

  // Fetch last visited thread ID
  const fetchLastVisitedThreadId = useCallback(async () => {
    try {
      setIsLoadingChats(true);
      const response = await axiosInstance.get('/api/last-accessed-threads/1/'); // Adjust the endpoint as needed
      if (response.data && response.data.thread) {
        setLastVisitedThreadId(response.data.thread); // Assuming the response contains a 'thread' field
      }
    } catch (error) {
      console.error(
        'Error fetching last visited thread ID:',
        error.response?.data || error.message,
      );
    } finally {
      setIsLoadingChats(false);
    }
  }, [axiosInstance]);

  const getUserStatus = async () => {
    try {
      // Make a POST request to the user-status API
      const saved_status = await axiosInstance.get('/api/user-status/');
      setUserStatusDescription(saved_status.data?.[0]?.description);
      setUserStatus(saved_status.data?.[0]?.status);
      setUserLocation(saved_status.data?.[0]?.work_location);
    } catch (error) {
      console.error('Error submitting status:', error.response?.data || error.message);
    }
  };

  // Fetch all threads
  const fetchThreads = useCallback(async () => {
    setIsLoadingThreads(true);
    try {
      const response = await axiosInstance.get('/api/threads/');
      const sortedThreads = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at),
      );
      setChats(sortedThreads); // update the chats state
      setHistoryItems(sortedThreads);
      if (sortedThreads.length > 0 && !defaultThread) {
        setDefaultThread(sortedThreads[0].thread_id);
      }
    } catch (error) {
      console.error('Error fetching threads:', error.response?.data || error.message);
    } finally {
      setIsLoadingThreads(false);
    }
  }, [axiosInstance, defaultThread]);

  // Fetch user info
  const fetchUserInfo = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/users/me/');
      const { username, title, id, oid } = response.data;
      setUser({ username, title, id, oid });
    } catch (error) {
      console.error('Error fetching user info:', error.response?.data || error.message);
    }
  }, [axiosInstance]);

  // Clear chat messages
  const clearMessages = () => setMessages([]);

  // Handle new thread creation
  const handleNewThread = async (newThread) => {
    if (isCreatingThread) return;
    setIsCreatingThread(true);
    setShowSuggestionGrid(false);
    setShowSuggestionGridAfterSpinner(false);

    try {
      if (!newThread) {
        const response = await axiosInstance.post('/api/threads/', {
          thread_name: `New Chat ${Date.now()}`,
          model_name: 'azure',
        });

        newThread = response.data;
      }

      setDefaultThread(newThread.thread_id);
      setShowHistoryGrid(false);
      setShowWelcomeModal(false);
    } catch (error) {
      console.error('Error creating thread:', error);
    } finally {
      setIsCreatingThread(false);
      setShowSuggestionGridAfterSpinner(true);
    }
  };

  // Handle delete confirmation modal
  const handleDeleteClick = (chat) => {
    if (!chat || !chat.thread_id) {
      console.error('Invalid chat object provided for deletion.');
      return;
    }
    setChatToDelete(chat);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!chatToDelete || !chatToDelete.thread_id) {
      console.error('No valid thread selected for deletion.');
      return;
    }
    try {
      await axiosInstance.delete(`/api/threads/${chatToDelete.thread_id}/`);
      await fetchThreads();
      if (chats.length > 1) {
        setDefaultThread(chats[1]?.thread_id);
      } else {
        setDefaultThread(null);
      }
      if (chatToDelete.thread_id === defaultThread) {
        navigate('/threads');
      }
    } catch (error) {
      console.error('Error deleting thread:', error.response?.data || error.message);
    } finally {
      setDeleteModalOpen(false);
    }
  };

  // Function to update thread title in chats and historyItems state
  const updateThreadTitle = async (threadId, newThreadName) => {
    try {
      // Make the API call to update the thread name
      await axiosInstance.put(`/api/threads/${threadId}/`, {
        thread_name: newThreadName,
      });

      // Update the local state
      setChats((prevChats) =>
        prevChats.map((chat) =>
          chat.thread_id === threadId ? { ...chat, thread_name: newThreadName } : chat,
        ),
      );

      setHistoryItems((prevHistoryItems) =>
        prevHistoryItems.map((item) =>
          item.thread_id === threadId ? { ...item, thread_name: newThreadName } : item,
        ),
      );
    } catch (error) {
      console.error('Error updating thread title:', error.response?.data || error.message);
    }
  };
  const fetchProfilePicture = async () => {
    try {
      if (user.oid != null) {
        // Define the API endpoint to get the profile picture
        const pictureUrl = `https://graph.microsoft.com/v1.0/users/${user.oid}/photo/$value`;

        // Make the API call to get the profile picture
        const response = await axiosInstance.get(pictureUrl, { responseType: 'arraybuffer' });

        if (response.data) {
          const base64Image = await arrayBufferToBase64(response.data);

          // Set the image URL as base64
          const imageUrl = `data:image/jpeg;base64,${base64Image}`;
          setProfilePicture(imageUrl);
        }

        // Set the profile picture state
      }
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  };
  const arrayBufferToBase64 = (arrayBuffer) => {
    return new Promise((resolve, reject) => {
      const blob = new Blob([arrayBuffer]);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1]; // Extract base64 from the result
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const getGreeting = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/api/greeting/`;

      const response = await axiosInstance.get(url, {
        params: {
          message:
            "Generate greeting for me just for 2-3 lines only based on my information. Do not start from hello or hi; direct start like 'It's great to see you back!' and so on. also add some emoji if possible.",
        },
      });

      if (response.data) {
        const finalMessage = response.data; // Assuming response.data contains the final message
        console.log('Final message from API:', finalMessage);
        setGreetingMessage(finalMessage);
        localStorage.setItem('greetingMessage', JSON.stringify(finalMessage)); // Store in local storage
      }
    } catch (error) {
      console.error('Error sending message to API:', error.message);
    }
  };

  useEffect(() => {
    fetchLastVisitedThreadId(); // Fetch last visited thread ID
    fetchThreads();
    fetchUserInfo();
  }, []);
  useEffect(() => {
    if (user.oid) {
      if (!profilePicture) fetchProfilePicture();
      if (!userStatusDescription) {
        getUserStatus();
      }

      const storedGreetingMessage = localStorage.getItem('greetingMessage');
      if (storedGreetingMessage) {
        setGreetingMessage(JSON.parse(storedGreetingMessage));
      } else {
        getGreeting(); // Fetch greeting if not in local storage
      }
    }
  }, [user]);

  return (
    <ChatContext.Provider
      value={{
        messages,
        setMessages,
        clearMessages,
        defaultThread,
        setDefaultThread,
        isGeneratingAnswer,
        setIsGeneratingAnswer,
        handleNewThread,
        user,
        showWelcomeModal,
        setShowWelcomeModal,
        showSuggestionGrid,
        setShowSuggestionGrid,
        showHistoryGrid,
        setShowHistoryGrid,
        handleDeleteClick,
        chats,
        setChats, // Provide setChats so it's accessible in components like ThreadPanel
        historyItems,
        isLoadingThreads,
        showSuggestionGridAfterSpinner,
        setShowSuggestionGridAfterSpinner,
        darkMode,
        setDarkMode,
        showPanelIcon,
        setShowPanelIcon,
        lastVisitedThreadId,
        setLastVisitedThreadId,
        formValue,
        setFormValue,
        updateThreadTitle,
        setHistoryItems,
        isLoadingChats,
        setIsLoadingChats,
        settingResponseType,
        setSettingResponseType,
        fetchThreads,
        searchQuery,
        setSearchQuery,
        searchResultExists,
        setSearchResultExists,
        profilePicture,
        greetingMessage,
        userStatusDescription,
        setUserStatusDescription,
        userStatus,
        setUserStatus,
        userLocation,
        setUserLocation,
        getUserStatus,
      }}
    >
      {children}

      {/* Delete Modal */}
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
    </ChatContext.Provider>
  );
};

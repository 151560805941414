import React from 'react';
import Modal from './Modal';

const DeleteModal = ({ isOpen, onClose, onConfirm }) => (
  <Modal title="Confirmation" modalOpen={isOpen} setModalOpen={onClose} size="smallDefault">
    <p className="text-black 2xl:text-[16px] xl:text-[15px] lg:text-[15px] md:text-[14px] sm:text-[13px] text-[12px] dark:text-white">
      Are you sure you want to delete this conversation?
    </p>
    <div className="flex justify-between mt-4">
      <button
        className="px-4 py-2 mr-2 bg-background-gray text-close-button rounded-lg 2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[11px]"
        onClick={onClose}
      >
        Cancel
      </button>
      <button
        className="px-4 py-2 text-white bg-red-button rounded-lg 2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[11px]"
        onClick={onConfirm}
      >
        Delete
      </button>
    </div>
  </Modal>
);

export default DeleteModal;

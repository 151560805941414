import React, { useEffect, useState, useRef } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { MdSend } from 'react-icons/md';
import { useChatContext } from '../context/chatContext';

// Import SVGs as React Components
import { ReactComponent as AttachFileIcon } from '../Svg/attach_file.svg';
import { ReactComponent as EmojiIcon } from '../Svg/emoji.svg';

const ChatInput = ({
  formValue,
  setFormValue,
  sendMessage,
  inputRef,
  fileInputRef,
  handleFileUpload,
  showHistoryPanel,
  isGeneratingAnswer,
  defaultThread,
  uploadError,
  showEmojiPicker,
  setShowEmojiPicker,
  handleEmojiClick,
  emojiPickerRef,
  isCognitiveEnabled,
  setIsCognitiveEnabled,
}) => {
  const { darkMode, LastVisitedThreadId } = useChatContext();
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef(null);
  const [isDocumentUploadEnabled, setIsDocumentUploadEnabled] = useState(false);

  const handleBlur = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setIsFocused(false);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text/plain');

    // Get the textarea element
    const textarea = e.target;

    // Get the current text in the textarea
    const currentValue = textarea.value;

    // Get the current selection range
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Replace the selected text (or insert at cursor position if no selection)
    const newValue = currentValue.substring(0, start) + pastedText + currentValue.substring(end);

    // Update the textarea value
    setFormValue(newValue);

    // Move the cursor to the end of the pasted text
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = start + pastedText.length;
    });
  };

  // Add event listener for clicks outside the component
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target) &&
        !formValue.trim() // Only shrink when formValue is empty
      ) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [formValue]); // Depend on formValue to ensure it updates correctly

  return (
    <form
      className="form bg-white dark:bg-[#1B1C1E]"
      onSubmit={(e) => {
        e.preventDefault();
        if (!formValue.trim()) return;

        sendMessage(formValue.replace(/\n/g, '\\n')); // Preserve line breaks before sending
        setFormValue(''); // Clear input after sending
      }}
    >
      <div className="relative w-full" ref={containerRef}>
        <div className="flex justify-center">
          <div
            style={{
              height: isFocused ? '100px' : '56px',
              borderTop: darkMode ? '1px solid #4b5563' : '1px solid #1414148A',
              borderRight: darkMode ? '1px solid #4b5563' : '1px solid #1414148A',
              borderBottom: isFocused ? '2px solid red' : '1px solid #4b5563',
              borderLeft: darkMode ? '1px solid #4b5563' : '1px solid #1414148A',
              borderRadius: '12px',
              backgroundColor: darkMode ? '#1B1C1E' : 'white',
            }}
            className={`relative flex mb-4 mr-3 transition-[width,margin] duration-300 ${
              showHistoryPanel
                ? '2xl:w-[896px] xl:w-[700px] lg:w-[500px] md:w-[350px] sm:w-[330px] w-[250px]'
                : '2xl:w-[896px] xl:w-[800px] lg:w-[650px] md:w-[570px] sm:w-[408px] w-[408px]'
            }`}
          >
            <textarea
              ref={inputRef}
              style={{
                height: isFocused ? '57px' : '40px',
                outline: 'none',
                border: 'none',
              }}
              className={`chatview__textarea-message resize-none overflow-auto w-full 
              ${
                isFocused
                  ? ' pl-3'
                  : '2xl:pl-[90px] xl:pl-[80px] lg:pl-[100px] md:pl-[90px] sm:pl-[80px] pl-[70px] 2xl:pt-[15px] xl:pt-[15px] lg:pt-[16px] md:pt-[17px] sm:pt-[16px] sm:text-[16px] pt-[16px]'
              } 
              2xl:text-[16px] xl:text-[15px] lg:text-[14px] md:text-[12px] text-[16px] 
              2xl:h-auto lg:h-auto md:h-auto transition-height duration-300 ease-in-out ${
                !defaultThread && !LastVisitedThreadId ? 'cursor-not-allowed' : ''
              } 
              ${isFocused ? 'placeholder:top-2 placeholder:left-2 placeholder:opacity-50' : ''}`}
              placeholder="Ask whatever you want..."
              value={formValue}
              onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && sendMessage(e)}
              onChange={(e) => setFormValue(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={handleBlur}
              disabled={(!defaultThread && !LastVisitedThreadId) || isGeneratingAnswer}
              onPaste={handlePaste}
            />

            <button
              type="submit"
              className={`chatview__btn-send ${
                isFocused ? 'mt-6' : 'mt-0'
              } 2xl:w-9 2xl:h-9 xl:w-9 xl:h-9 lg:w-9 lg:h-9 md:w-9 md:h-9 sm:w-6 sm:w-[40px] sm:h-[40px] w-[40px] h-[40px] ${
                formValue && !isGeneratingAnswer && defaultThread
                  ? 'bg-red-button dark:bg-red-button'
                  : 'bg-gray-300 dark:bg-gray-600 cursor-not-allowed'
              }`}
              disabled={!formValue || isGeneratingAnswer || !defaultThread}
            >
              <MdSend className="text-white" />
            </button>

            {/* Attach File Icon */}
            <AttachFileIcon
              width="22"
              height="22"
              onClick={() => fileInputRef.current.click()}
              className={`fill-icon-light-fill dark:fill-white absolute ${
                isFocused ? 'mt-6' : 'mt-0'
              } 2xl:left-4 xl:left-4 lg:left-4 md:left-4 sm:left-4 left-2 
              top-1/2 transform -translate-y-1/2 cursor-pointer text-close-button 
              2xl:size-[24px] xl:size-[22px] lg:text-[20px] md:text-[18px] sm:text-[24px] text-[12px] 
              dark:text-gray-icon`}
              role="img"
              aria-label="Attach File Icon"
            />

            <input
              type="file"
              id="fileUpload"
              accept="*/*"
              ref={fileInputRef}
              multiple
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />

            <div
              className={`absolute 2xl:left-22 xl:left-22 lg:left-20 md:left-18 sm:left-16 left-16 top-3/4 transform -translate-y-1/2 
              ${
                isFocused ? 'opacity-100' : 'opacity-0 pointer-events-none'
              } transition-opacity duration-300`}
            >
              <label className="inline-flex items-center cursor-pointer ml-3 mt-1">
                <input
                  type="checkbox"
                  checked={isDocumentUploadEnabled}
                  onChange={() => setIsDocumentUploadEnabled((prev) => !prev)}
                  className="sr-only peer"
                />
                <div className="relative w-9 h-5 bg-transparent border border-gray-300 rounded-full peer-checked:border-red-button after:content-[''] after:absolute after:top-[1px] after:left-[2px] after:bg-gray-400 after:border after:border-gray-400 after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:after:translate-x-full peer-checked:after:bg-red-button peer-checked:after:border-red-button"></div>
                <div className="text-sm text-close-button ml-1 dark:text-gray-icon">
                  Cognitive search
                </div>
              </label>
            </div>

            {/* Emoji Icon */}
            <EmojiIcon
              width="22"
              height="22"
              onClick={() => setShowEmojiPicker((prev) => !prev)}
              className={`fill-icon-light-fill dark:fill-white absolute ${
                isFocused ? 'mt-6' : 'mt-0'
              } 2xl:left-14 xl:left-13 lg:left-12 md:left-10 sm:left-10 left-10 
              top-1/2 transform -translate-y-1/2 cursor-pointer text-close-button
              2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px]
              dark:text-gray-icon`}
              role="img"
              aria-label="Emoji Icon"
            />
          </div>
        </div>

        {showEmojiPicker && (
          <div ref={emojiPickerRef} className="absolute bottom-[80px] left-[80px] z-10">
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </div>
        )}

        {uploadError && <div className="text-red-button mt-2 text-xs">{uploadError}</div>}
      </div>
    </form>
  );
};

export default ChatInput;

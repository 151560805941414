import React from 'react';
import { useChatContext } from '../context/chatContext';
import { MdGrain } from 'react-icons/md';
import { MdRestartAlt } from 'react-icons/md';

const SuggestionGrid = ({ showHistoryPanel, sendMessage, setAnalysisStep, hideChatInput }) => {
  const { showSuggestionGrid } = useChatContext();

  if (!showSuggestionGrid) return null;

  const suggestions = [
    'What is the best approach for digital transformation?',
    'Assist with creative writing and task description',
    'List down features to simplify the web building experience',
    'Write a list of tools that help AVA for seamless integration',
    'Tell me about DVB Infosystems and digital transformation',
  ];

  const handleSuggestionClick = async (suggestion) => {
    hideChatInput(); // Hide the chat input when a suggestion is clicked
    // Start the analysis sequence
    setAnalysisStep(0); // Start with analyzing data

    sendMessage({
      preventDefault: () => {}, // Fake event to maintain compatibility
      target: { value: suggestion }, // Pass the suggestion directly
    });

    // Simulate the analysis steps
    await new Promise((resolve) => {
      setTimeout(() => {
        setAnalysisStep(1); // Analyzing complete
        resolve();
      }, 1000); // Simulate analyzing time
    });

    await new Promise((resolve) => {
      setTimeout(() => {
        setAnalysisStep(2); // Generating response
        resolve();
      }, 1000); // Simulate generating time
    });

    await new Promise((resolve) => {
      setTimeout(() => {
        setAnalysisStep(3); // Show generating response with checkmark
        resolve();
      }, 1000); // Adjust this delay as needed
    });

    // Now set to typing
    await new Promise((resolve) => {
      setTimeout(() => {
        setAnalysisStep(4); // Show typing animation
        resolve();
      }, 1000); // Adjust this delay as needed
    });
  };

  return (
    <div className="inset-0 flex items-center justify-center">
      <div className="p-4 rounded-lg ">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className={`flex items-center justify-center bg-text-gray p-2 text-black rounded-lg shadow-md cursor-pointer dark:bg-grid-gray ${
                showHistoryPanel
                  ? '2xl:w-[270px] 2xl:h-[60px] xl:w-[200px] xl:h-[55px] lg:w-[140px] lg:h-[50px] md:w-[135px] md:h-[45px] sm:w-[125px] sm:h-[40px] w-[328px] h-[45px]'
                  : '2xl:w-[270px] 2xl:h-[76px] xl:w-[240px] xl:h-[70px] lg:w-[180px] lg:h-[65px] md:w-[200px] md:h-[60px] sm:w-[328px] sm:h-[65px] w-[328px] h-[60px]'
              } transition-all duration-300`}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              <MdGrain
                className={`text-close-button 2xl:mr-4 lg:mr-3.5 md:mr-3 sm:mr-2.5 mr-2 dark:text-white ${
                  showHistoryPanel
                    ? '2xl:text-[20px] xl:text-[19px] lg:text-[18px] md:text-[16px] sm:text-[24px] text-[20px]'
                    : '2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[24px] text-[20px]'
                }`}
              />
              <span
                className={`dark:text-white ${
                  showHistoryPanel
                    ? '2xl:text-[13px] xl:text-[12px] lg:text-[9px] md:text-[9px] sm:text-[16px] '
                    : '2xl:text-[15px] xl:text-[14px] lg:text-[13px] md:text-[11px] sm:text-[16px] '
                }`}
              >
                {suggestion}
              </span>
            </div>
          ))}
        </div>
        <div className="flex items-center space-x-2 mt-3">
          <MdRestartAlt className="2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[24px] text-[24px] cursor-pointer dark:text-gray-icon" />
          <p className="font-semibold 2xl:text-[16px] xl:text-[15px] lg:text-[14px] md:text-[12px] sm:text-[16px] text-[16px]">
            Refresh prompts
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuggestionGrid;

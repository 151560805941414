import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { ChatContextProvider } from './context/chatContext';
import ChatView from './components/ChatView';
import RealSignUp from './components/RealSignUp';
import Home from './components/Home';
import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
      <ChatContextProvider>
        <Routes>
          <Route
            path="/login"
            element={
              <AuthenticatedTemplate>
                <Navigate to="/home" replace />
              </AuthenticatedTemplate>
            }
          />
          <Route
            path="/threads/:threadId/:select?"
            element={
              <>
                <AuthenticatedTemplate>
                  <ChatView />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <RealSignUp />
                </UnauthenticatedTemplate>
              </>
            }
          />

          <Route
            path="/threads/"
            element={
              <>
                <AuthenticatedTemplate>
                  <ChatView />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            }
          />
          <Route
            path="/home"
            element={
              <>
                <AuthenticatedTemplate>
                  <Home />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Navigate to="/" replace />
                </UnauthenticatedTemplate>
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <UnauthenticatedTemplate>
                  <RealSignUp />
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  <Navigate to="/home" replace />
                </AuthenticatedTemplate>
              </>
            }
          />
          {/* Fallback route for unmatched paths */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ChatContextProvider>
    </Router>
  );
}

export default App;

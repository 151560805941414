import React, { useState, useEffect } from 'react';
// import ReactMarkdown from 'react-markdown';
import robo from '../assets/robo.png';
import { MdOutlineStopCircle, MdSend } from 'react-icons/md';
// import DislikeModal from './DislikeModal';
// import LikeModal from './LikeModal';
import { useChatContext } from '../context/chatContext';
import useAxiosInstance from '../utils/axiosInstance';
import clsx from 'clsx';

// Import SVGs as React components
import { ReactComponent as EditIcon } from '../Svg/edit.svg';
import { ReactComponent as CopyIcon } from '../Svg/copy.svg';
import { ReactComponent as AudioIcon } from '../Svg/text_to_speech.svg';
import { ReactComponent as LikeIcon } from '../Svg/like.svg';
import { ReactComponent as DislikeIcon } from '../Svg/dislike.svg';

import parse from 'html-react-parser';

// Preprocessing function to replace hashes with corresponding HTML headings
const preprocessMarkdown = (markdownText) => {
  return markdownText
    .split('\n')
    .map((line) => {
      // Match # headings
      const headingMatch = line.match(/^(#{1,6})\s+(.*)$/);
      if (headingMatch) {
        const [_, hashes, content] = headingMatch;
        const headingLevel = hashes.length;

        // Define consistent sizes for headings
        const sizeClass =
          headingLevel === 1
            ? 'text-2xl' // Adjust this for h1
            : headingLevel === 2
            ? 'text-xl' // Adjust this for h2
            : headingLevel === 3
            ? 'text-lg' // Adjust this for h3
            : headingLevel === 4
            ? 'text-base' // Adjust this for h4
            : headingLevel === 5
            ? 'text-base' // Adjust this for h5
            : 'text-base'; // Adjust this for h6

        // Process highlighting within heading content
        const highlightedContent = content.replace(
          /==(.+?)==/g,
          '<mark class="bg-yellow-200 dark:bg-yellow-700">$1</mark>',
        );

        return `<h${headingLevel} class="font-bold ${sizeClass} mb-2 break-words">${highlightedContent}:</h${headingLevel}>`;
      }

      // Match **bold text** for headings
      const boldHeadingMatch = line.match(/^\*\*(.+?)\*\*$/);
      if (boldHeadingMatch) {
        const boldContent = boldHeadingMatch[1];
        // Process highlighting within bold heading
        const highlightedBoldContent = boldContent.replace(
          /==(.+?)==/g,
          '<mark class="bg-yellow-200 dark:bg-yellow-700">$1</mark>',
        );
        return `<h2 class="font-bold text-xl mb-2 break-words">${highlightedBoldContent}</h2>`;
      }

      // Replace inline **bold text**
      const inlineBoldProcessed = line.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');

      // Replace ==highlighted text==
      const inlineHighlightedProcessed = inlineBoldProcessed.replace(
        /==(.+?)==/g,
        '<mark class="bg-yellow-200 dark:bg-yellow-700">$1</mark>',
      );

      // Wrap remaining text in a paragraph
      return `<p class="font-normal text-base mx-2 break-words">${inlineHighlightedProcessed}</p>`;
    })
    .join('\n');
};

const ChatMessage = ({ message, showHistoryPanel, setFormValue, inputRef }) => {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isCopied, setIsCopied] = useState(false); // State for copy popover
  const { isGeneratingAnswer, searchQuery, profilePicture } = useChatContext();
  const [editingMessage, setEditingMessage] = useState(false); // New state for editing
  const [newMessageText, setNewMessageText] = useState(message.text); // New state for new message text
  const [isLiked, setIsLiked] = useState(message.is_like); // Track if the message is liked
  const [isDisliked, setIsDisliked] = useState(message.is_dislike); // Track if the message is disliked
  const [aggregatedContent, setAggregatedContent] = useState(''); // Holds the full AI response
  const [isLoading, setIsLoading] = useState(false);

  const axiosInstance = useAxiosInstance(); // Add this line to get axios instance

  // Parse and aggregate content
  useEffect(() => {
    if (message.text) {
      try {
        const lines = message.text.split('\n'); // Split the text by newlines
        let combinedContent = '';

        lines.forEach((line) => {
          try {
            const parsed = JSON.parse(line.trim() + '\n'); // Parse each line as JSON
            if (parsed.content && parsed.content.trim()) {
              combinedContent += parsed.content; // Append valid content
            }
          } catch {
            // Handle plain text or invalid JSON
            if (line.trim()) {
              combinedContent += line.trim() + '\n';
            }
          }
        });

        setAggregatedContent(combinedContent); // Set the aggregated content
      } catch (error) {
        console.error('Error processing message.text:', error);
      }
    }
  }, [message.text]);

  const { text, ai } = message;
  const isHuman = !ai;

  const preprocessTextForHighlighting = (inputText, query) => {
    const trimmedQuery = query.trim();
    if (!trimmedQuery) return inputText; // No highlight if only whitespace or empty

    // Escape special chars
    const escapedQuery = trimmedQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Replace matches, conditionally highlight
    return inputText.replace(new RegExp(`(${escapedQuery})`, 'gi'), (match, p1) => {
      // Check if matched text is purely whitespace
      if (p1.trim() === '') {
        return p1; // Return unchanged if only whitespace
      } else {
        return `==${p1}==`; // Highlight as bold markdown
      }
    });
  };

  // Processed text with highlighting
  const highlightedAggregatedContent = preprocessTextForHighlighting(
    aggregatedContent,
    searchQuery,
  );

  // Apply markdown preprocessing to the highlighted content
  const processedText = preprocessMarkdown(highlightedAggregatedContent.replace(/\\n/g, '<br />'));

  // Replace mockApiRequest with real API calls
  const toggleReaction = async (type) => {
    setIsLoading(true);
    try {
      const endpoint = type === 'like' ? 'toggle_reaction' : 'toggle_dislike';
      await axiosInstance.get(`/api/chat-reactions/${message.chat_id}/${endpoint}/`);
      return { success: true };
    } catch (error) {
      console.error(`Error toggling ${type}:`, error);
      return { success: false };
    } finally {
      setIsLoading(false);
    }
  };

  const handleLikeIconClick = async () => {
    if (!isLoading) {
      try {
        if (isDisliked) {
          // First toggle off dislike if message was disliked
          const dislikeResponse = await toggleReaction('dislike');
          if (!dislikeResponse.success) return;
        }

        if (!isLiked) {
          // Toggle like on if not already liked
          const likeResponse = await toggleReaction('like');
          if (likeResponse.success) {
            setIsLiked(true);
            setIsDisliked(false);
          }
        } else {
          // Toggle like off if already liked
          const likeResponse = await toggleReaction('like');
          if (likeResponse.success) {
            setIsLiked(false);
          }
        }
      } catch (error) {
        console.error('Error handling like:', error);
      }
    }
  };

  const handleDislikeIconClick = async () => {
    if (!isLoading) {
      try {
        if (isLiked) {
          // First toggle off like if message was liked
          const likeResponse = await toggleReaction('like');
          if (!likeResponse.success) return;
        }

        if (!isDisliked) {
          // Toggle dislike on if not already disliked
          const dislikeResponse = await toggleReaction('dislike');
          if (dislikeResponse.success) {
            setIsDisliked(true);
            setIsLiked(false);
          }
        } else {
          // Toggle dislike off if already disliked
          const dislikeResponse = await toggleReaction('dislike');
          if (dislikeResponse.success) {
            setIsDisliked(false);
          }
        }
      } catch (error) {
        console.error('Error handling dislike:', error);
      }
    }
  };

  const toggleAudioState = () => {
    setIsAudioPlaying((prevState) => !prevState); // Toggle the state
  };

  const handleCopy = () => {
    if (!aggregatedContent) return; // Prevent copying empty content

    navigator.clipboard
      .writeText(aggregatedContent)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset "Copied" message after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  };

  const handleEditClick = () => {
    setFormValue(text);
    inputRef.current.focus();
  };

  const handleEditSave = () => {
    // Logic to save the edited message
    setEditingMessage(false);
  };

  const handleCancelEdit = () => {
    setEditingMessage(false);
  };

  // Shared width logic for both AI and Human messages
  const maxWidthClass = clsx(
    '2xl:max-w-[800px]',
    showHistoryPanel
      ? 'xl:max-w-[600px] lg:max-w-[400px] md:max-w-[270px] sm:max-w-[220px] max-w-[120px]'
      : 'xl:max-w-[700px] lg:max-w-[530px] md:max-w-[490px] sm:max-w-[380px] max-w-[210px]',
  );

  return (
    <div className={clsx('mb-6 flex w-full', isHuman ? 'justify-end' : 'justify-start')}>
      {isHuman ? (
        <div className="flex text-black items-center w-full">
          <div className={`flex flex-1 justify-end items-center mr-0`}>
            {!editingMessage && (
              <EditIcon
                width="20"
                height="20"
                className="fill-icon-light-fill dark:fill-white mr-2 cursor-pointer text-close-button dark:text-gray-icon
                           2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[14px]"
                onClick={handleEditClick}
                role="img"
                aria-label="Edit icon"
              />
            )}
            {editingMessage ? (
              <div className="flex flex-col w-[700px]">
                <input
                  type="text"
                  value={newMessageText}
                  onChange={(e) => setNewMessageText(e.target.value)}
                  className="border-2 border-b-red-button bg-transparent rounded-lg p-1.5 mb-2 w-full dark:text-white"
                />
                <div className="flex space-x-4 w-full justify-end">
                  <button
                    onClick={handleCancelEdit}
                    className="px-3 py-1 bg-background-gray text-close-button rounded-lg"
                  >
                    Cancel
                  </button>
                  <div className="flex">
                    <button
                      onClick={handleEditSave}
                      className="flex items-center px-3 py-1 text-white bg-red-button rounded-lg"
                    >
                      <MdSend className="mr-1" />
                      Save
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  maxWidthClass,
                  'flex bg-background-gray inline-block text-grey rounded-[24px]',
                  '2xl:p-3 xl:p-3 lg:p-2 md:p-2 sm:p-1.5 p-1.5 w-auto',
                  'dark:bg-grid-gray dark:text-white text-left',
                  '2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[11px] sm:text-[10px] text-[8px]',
                )}
              >
                {parse(processedText)}
              </div>
            )}
            <div className="2xl:ml-2 xl:ml-2 lg:ml-2 md:ml-1.5 sm:ml-1.5 ml-1">
              {profilePicture ? (
                <img
                  src={profilePicture}
                  alt="User avatar"
                  className="2xl:w-[40px] xl:w-[38px] lg:w-[35px] md:w-[30px] sm:w-[28px] w-[25px] rounded-full"
                />
              ) : (
                <div className="2xl:w-[40px] 2xl:h-[40px] xl:w-[38px] xl:h-[38px] lg:w-[35px] lg:h-[35px] md:w-[30px] md:h-[30px] sm:w-[28px] sm:h-[28px] w-[25px] h-[25px] rounded-full object-cover ml-1 bg-gray-200" />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={`flex flex-1 justify-start mb-4`}>
          <div className="mr-2">
            <img
              src={robo}
              alt="AI avatar"
              className="2xl:w-[40px] xl:w-[38px] lg:w-[35px] md:w-[30px] sm:w-[28px] w-[25px] rounded-full"
            />
          </div>
          <div>
            <div
              className={`${maxWidthClass} bg-background-gray text-grey rounded-2xl 
          2xl:p-3 xl:p-3 lg:p-2 md:p-2 sm:p-2 p-1.5 
          relative transition-all duration-100 dark:bg-grid-gray dark:text-white text-left 
          2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[11px] sm:text-[10px] text-[8px]`}
              style={{
                display: 'block',
                wordWrap: 'break-word',
                overflowWrap: 'anywhere',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                boxSizing: 'border-box',
              }}
            >
              {parse(processedText)}
            </div>

            {!isGeneratingAnswer && (
              <div className="flex text-close-button gap-2 text-1.5rem m-3 cursor-pointer dark:text-gray-icon">
                {isAudioPlaying ? (
                  <MdOutlineStopCircle
                    className="text-white bg-red-button rounded-lg 
                    2xl:text-[18px] xl:text-[16px] lg:text-[14px] md:text-[13px] sm:text-[10px] text-[8px]"
                    onClick={toggleAudioState}
                    aria-label="Stop Audio"
                  />
                ) : (
                  <AudioIcon
                    width="16"
                    height="16"
                    className="
                      fill-icon-light-fill 
                      dark:fill-white 
                      2xl:text-[18px] xl:text-[16px] 
                      lg:text-[14px] md:text-[13px] sm:text-[11px] text-[10px]
                    "
                    onClick={toggleAudioState}
                    role="img"
                    aria-label="Play Audio"
                  />
                )}
                <div className="relative">
                  <CopyIcon
                    width="16"
                    height="16"
                    className="
                      fill-icon-light-fill 
                      dark:fill-white
                      2xl:text-[18px] xl:text-[16px] lg:text-[14px] 
                      md:text-[13px] sm:text-[11px] text-[10px]
                    "
                    onClick={handleCopy}
                    role="img"
                    aria-label="Copy icon"
                  />
                  {isCopied && (
                    <div
                      className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-success text-white py-1 px-2 rounded-lg shadow
                      2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[8px]"
                    >
                      Copied
                    </div>
                  )}
                </div>
                <LikeIcon
                  width="16"
                  height="16"
                  className={clsx(
                    'fill-icon-light-fill cursor-pointer transition-transform duration-200',
                    '2xl:text-[18px] xl:text-[16px] lg:text-[14px] md:text-[13px] sm:text-[11px] text-[10px] dark:fill-white',
                    isLiked ? 'scale-125 fill-red-button dark:!fill-red-button' : 'scale-100',
                    isLoading && 'opacity-50 cursor-not-allowed',
                  )}
                  onClick={handleLikeIconClick}
                  role="img"
                  aria-label="Like icon"
                />
                <DislikeIcon
                  width="16"
                  height="16"
                  className={clsx(
                    'fill-icon-light-fill cursor-pointer transition-transform duration-200',
                    '2xl:text-[18px] xl:text-[16px] lg:text-[14px] md:text-[13px] sm:text-[11px] text-[10px] dark:fill-white',
                    isDisliked ? 'scale-125 fill-red-button dark:!fill-red-button' : 'scale-100',
                    isLoading && 'opacity-50 cursor-not-allowed',
                  )}
                  onClick={handleDislikeIconClick}
                  role="img"
                  aria-label="Dislike icon"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatMessage;

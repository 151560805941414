import React from 'react';
import CopyIcon from '../Svg/copy.svg';

const FilesSection = () => {
  return (
    <div className="p-6 2xl:mr-[64px] 2xl:mb-[224px] xl:ml-[50px] xl:mb-[224px] lg:ml-[20px] lg:mb-[200px] w-[800px] z-10">
      <h2 className="2xl:text-[20px] xl:text-[18px] lg:text-[17px] md:text-[16px] sm:text-[14px] text-[12px] text-black font-semibold mb-4 dark:text-white">
        Files
      </h2>
      <ul className="cursor-pointer">
        {Array(5)
          .fill('file name')
          .map((file, index, arr) => (
            <React.Fragment key={index}>
              <li className="flex items-center space-x-3 2xl:p-3 xl:p-3 lg:p-3 md:p-2 sm:p-2 p-1.5 rounded-lg transition cursor-pointer">
                <span className="2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px] text-close-button dark:text-white">
                  <img src={CopyIcon} alt="Images" width="20" height="20" />
                </span>
                <span className="2xl:text-[16px] xl:text-[15px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px] text-black dark:text-white">
                  {file}
                </span>
              </li>
              {index < arr.length - 1 && <hr className="border-gray-300" />}
            </React.Fragment>
          ))}
      </ul>
    </div>
  );
};

export default FilesSection;

import React, { useState } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { FaRegDotCircle } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa6';
import { HiMiniXMark } from 'react-icons/hi2';

import { FiGitCommit } from 'react-icons/fi';
import { useChatContext } from '../context/chatContext';
import { GoHome } from 'react-icons/go';
import { ReactComponent as OfficeIcon } from '../Svg/office.svg';
import clsx from 'clsx';

export const NewDropdown = ({ isOpen, toggleNewDropdown, onSelect }) => {
  if (!isOpen) return null;

  return (
    <div className="absolute w-[159px] top-[60px] -left-[160px] bg-white shadow-md rounded-lg z-20 dark:bg-black dark:text-white">
      <div
        className="flex gap-4 p-2 cursor-pointer hover:bg-[#FF00000D]"
        onClick={() => {
          onSelect('Available');
          toggleNewDropdown(false);
        }}
      >
        <BsCheckCircle className="text-success 2xl:text-[20px] xl:text-[18px] lg:text-[17px] md:text-[15px] sm:text-[13px] text-[10px]" />
        <span className="text-black 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] dark:text-white">
          Available
        </span>
      </div>
      <div
        className="flex gap-4 p-2 cursor-pointer hover:bg-[#FF00000D]"
        onClick={() => {
          onSelect('Do not disturb');
          toggleNewDropdown(false);
        }}
      >
        <FaRegDotCircle className="text-red-button 2xl:text-[20px] xl:text-[18px] lg:text-[17px] md:text-[15px] sm:text-[13px] text-[10px]" />
        <span className="text-black 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] dark:text-white">
          Do not disturb
        </span>
      </div>
      <div
        className="flex gap-4 p-2 cursor-pointer hover:bg-[#FF00000D]"
        onClick={() => {
          onSelect('Set away');
          toggleNewDropdown(false);
        }}
      >
        <FiGitCommit className="text-close-button 2xl:text-[20px] xl:text-[18px] lg:text-[17px] md:text-[15px] sm:text-[13px] text-[10px] fill-icon-light-fill stroke-icon-light-stroke dark:fill-white dark:stroke-white" />
        <span className="text-black 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] dark:text-white">
          Set away
        </span>
      </div>
    </div>
  );
};

export const WorkLocationMenu = ({ isOpen, toggleWorkLocationMenu, onSelect }) => {
  if (!isOpen) return null;

  return (
    <div className="absolute w-[159px] top-[100px] -left-[160px] bg-white shadow-md rounded-lg z-20 dark:bg-black dark:text-white">
      <div
        className={clsx('flex gap-2 p-2 cursor-pointer hover:bg-[#FF00000D]')}
        onClick={() => {
          onSelect('office');
          toggleWorkLocationMenu(false);
        }}
      >
        <OfficeIcon
          alt="Office"
          width="20"
          height="20"
          className="text-close-button 2xl:text-[20px] xl:text-[19px] lg:text-[18px] md:text-[15px] sm:text-[13px] text-[10px] fill-icon-light-fill dark:fill-white"
        />
        <span className="2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] text-black dark:text-white">
          Office
        </span>
      </div>
      <div
        className={clsx('flex gap-2 p-2 cursor-pointer hover:bg-[#FF00000D]')}
        onClick={() => {
          onSelect('home');
          toggleWorkLocationMenu(false);
        }}
      >
        <GoHome className="text-close-button 2xl:text-[20px] xl:text-[19px] lg:text-[18px] md:text-[15px] sm:text-[13px] text-[10px] dark:text-white" />
        <span className="2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[8px] text-black dark:text-white">
          Home
        </span>
      </div>
    </div>
  );
};

export const StatusTextarea = ({ isOpen, setIsStatusTextareaOpen, onSubmit }) => {
  const { userStatusDescription } = useChatContext();
  const [localStatusText, setLocalStatusText] = useState(userStatusDescription);

  // Maximum allowed characters
  const MAX_CHARS = 100;

  // Characters remaining
  const remainingChars = MAX_CHARS - localStatusText?.length;
  // Check if user has exceeded the limit
  const isOverLimit = localStatusText?.length === MAX_CHARS;

  if (!isOpen) return null;

  const handleSubmit = () => {
    // Additional validation checks
    if (localStatusText.trim() === '') {
      console.log('Status cannot be empty');
      return;
    }

    if (isOverLimit) {
      console.log('Status exceeds maximum allowed characters');
      return;
    }

    // Submit if valid
    onSubmit(localStatusText);
    setIsStatusTextareaOpen(false);
  };

  const handleIconClick = () => {
    console.log('FaCheck clicked');
    handleSubmit();
  };

  const handleCancel = () => {
    setIsStatusTextareaOpen(false);
    setLocalStatusText(userStatusDescription); // Reset to original text
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText?.length <= MAX_CHARS) {
      setLocalStatusText(newText);
    }
  };

  return (
    <>
      {isOpen && <div className="fixed inset-0 bg-black opacity-50 z-10" />}
      <div className="absolute top-full -left-[90px] z-20">
        <div className="relative">
          <textarea
            className={clsx(
              'w-[270px] text-sm h-28 border rounded-lg p-2 pr-20', // Increased right padding for both icons
              'text-black placeholder:text-black bg-white',
              'dark:bg-black dark:text-white dark:placeholder:text-white',
            )}
            placeholder="type here..."
            onChange={handleTextChange}
            value={localStatusText}
          />

          {/* Icons container */}
          <div className="absolute top-2 right-2 flex gap-2">
            {/* Cancel icon */}
            <div className="cursor-pointer" onClick={handleCancel}>
              <HiMiniXMark className="text-close-button dark:text-white" />
            </div>

            {/* Submit icon */}
            <div
              className={clsx(
                'cursor-pointer',
                isOverLimit && 'opacity-50 cursor-not-allowed pointer-events-none',
              )}
              onClick={handleIconClick}
            >
              <FaCheck className="text-close-button dark:text-white" />
            </div>
          </div>
        </div>

        {/* Display remaining characters or limit exceeded message */}
        <div
          className={clsx(
            'text-right text-xs mt-1',
            isOverLimit ? 'text-red-button' : 'text-white',
          )}
        >
          {isOverLimit ? 'Limit exceeded' : `${remainingChars} characters remaining`}
        </div>
      </div>
    </>
  );
};

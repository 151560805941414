import React, { useState, useEffect, useRef } from 'react';
import ShareChat from './ShareChat';
import FilesSection from './FilesSection';
import RenameModal from './RenameModal';
import { useChatContext } from '../context/chatContext';
import { ReactComponent as ImagesIcon } from '../Svg/images.svg';
import { ReactComponent as ChatIcon } from '../Svg/chat.svg';
import { ReactComponent as SearchIcon } from '../Svg/search.svg';
import { ReactComponent as MoreIcon } from '../Svg/more_horiz.svg';
import { ReactComponent as CloseIcon } from '../Svg/close.svg';
import { ReactComponent as WriteIcon } from '../Svg/write.svg';
import { ReactComponent as ShareIcon } from '../Svg/share.svg';
import { ReactComponent as DownloadIcon } from '../Svg/download.svg';
import { ReactComponent as ArrowRightIcon } from '../Svg/arrow_right.svg';
import { ReactComponent as DeleteIcon } from '../Svg/delete.svg';
import '../index.css';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
const ChatMenu = ({ onImageIconClick, onChatIconClick, toPDF, showHistoryPanel }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isExportSubMenuOpen, setIsExportSubMenuOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isFilesSectionOpen, setIsFilesSectionOpen] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [threadName, setThreadName] = useState('');
  const searchInputRef = useRef(null); // Ref for the search input
  const menuRef = useRef(null); // Ref for the menu
  const searchRef = useRef(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [activeMenuIcon, setActiveMenuIcon] = useState('chat');
  const {
    handleDeleteClick,
    defaultThread,
    chats,
    messages,
    updateThreadTitle,
    fetchThreads,
    searchQuery,
    setSearchQuery,
    searchResultExists,
  } = useChatContext();

  const handleImageIconClick = () => onImageIconClick();

  const handleRenameClick = (event) => {
    setIsRenameModalOpen(true);
    const { top, left, height } = event.currentTarget.getBoundingClientRect();
    const currentThread = chats.find((chat) => chat.thread_id === defaultThread);
    setThreadName(currentThread ? currentThread.thread_name : '');
    setModalPosition({ top: top + height - 55, left: left - 15 });
  };

  const handleCloseRenameModal = () => setIsRenameModalOpen(false);

  const handleConfirmRename = async () => {
    try {
      await updateThreadTitle(defaultThread, threadName);
      await fetchThreads();
    } catch (error) {
      console.error('Error updating thread title:', error);
    } finally {
      setIsRenameModalOpen(false);
    }
  };

  const closeSearchBar = () => {
    setSearchQuery(''); // Clear the search query
    setShowSearchBar(false); // Hide the search bar
    setActiveMenuIcon('chat');
  };

  // Auto-focus the input when search bar is shown
  useEffect(() => {
    if (showSearchBar && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showSearchBar]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the search bar if clicked outside
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target) &&
        !event.target.closest('.search-icon')
      ) {
        closeSearchBar();
        setActiveMenuIcon('chat');
      }

      // Close the menu if clicked outside
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        setActiveMenuIcon('chat');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearchIconClick = () => setShowSearchBar((prev) => !prev);

  const handleSearchSubmit = () => {
    if (!searchResultExists && searchQuery.trim() !== '') {
      toast.error('No results found');
    }
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleExportSubMenu = () => setIsExportSubMenuOpen(!isExportSubMenuOpen);

  const handleShareIconClick = () => setIsShareModalOpen(true);
  const closeShareModal = () => setIsShareModalOpen(false);

  const handleMenuIconClick = (icon) => setActiveMenuIcon(icon);

  const handleDownload = async (format) => {
    const currentMessages = messages;
    if (!currentMessages || currentMessages.length === 0) return;

    const chatContent = currentMessages
      .map((message) => `${message.ai ? 'AI' : 'User'}: ${message.text}\n`)
      .join('\n');

    let fileBlob;
    let fileName = `chat-${defaultThread}`;

    if (format === 'docx') {
      fileBlob = await generateDOCX(chatContent);
      fileName += '.docx';
    } else if (format === 'txt') {
      const formattedContent = formatForTXT(chatContent);
      fileBlob = new Blob([formattedContent], { type: 'text/plain' });
      fileName += '.txt';
    }

    const url = URL.createObjectURL(fileBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  };

  // Format for TXT
  const formatForTXT = (content) => {
    const lines = content.split('\n');
    const formattedLines = lines.map((line) => {
      return line.replace(/\*\*(.*?)\*\*/g, (match, p1) => `*${p1}*`);
    });
    return formattedLines.join('\n');
  };

  const generateDOCX = async (content) => {
    const { Packer, Document, Paragraph, TextRun, HeadingLevel } = await import('docx');

    const lines = content.split('\n');
    const paragraphs = lines.map((line) => {
      const words = line.split(/(\*\*.*?\*\*)/g).map((part) => {
        const isBold = part.startsWith('**') && part.endsWith('**');
        const text = isBold ? part.slice(2, -2) : part;
        return new TextRun({
          text: text,
          bold: isBold,
        });
      });
      return new Paragraph({
        children: [...words],
        spacing: { after: 200 },
      });
    });

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              text: 'Chat History',
              heading: HeadingLevel.HEADING_1,
            }),
            ...paragraphs,
          ],
        },
      ],
    });

    return Packer.toBlob(doc);
  };

  return (
    <div className="relative flex items-center  z-20  chat-menu ">
      {/* Chat Icon */}
      <div
        className={clsx(
          'relative cursor-pointer 2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px] py-1 px-2 flex items-center',
          {
            'text-red-button border-b-2 border-red-button': activeMenuIcon === 'chat',
            'text-black dark:text-gray-icon': activeMenuIcon !== 'chat',
          },
        )}
        onClick={() => {
          onChatIconClick();
          handleMenuIconClick('chat');
        }}
      >
        <ChatIcon
          width="20"
          height="20"
          className={clsx('fill-icon-light-fill', {
            'fill-red-button': activeMenuIcon === 'chat',
            'dark:fill-white': activeMenuIcon !== 'chat',
          })}
          role="img"
          aria-label="Chat icon"
        />
      </div>

      {/* Images Icon */}
      <div
        className={`cursor-pointer 2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px] ${
          activeMenuIcon === 'image' ? 'text-red-button' : 'text-close-button dark:text-gray-icon'
        }`}
        onClick={() => {
          // handleImageIconClick();
          // handleMenuIconClick('image');
        }}
      >
        <ImagesIcon
          width="20"
          height="20"
          className="fill-gray-300   cursor-not-allowed"
          role="img"
          aria-label="Images icon"
          title="Coming Soon"
        />
      </div>

      {isFilesSectionOpen && (
        <div className="absolute top-8 right-0 w-[300px]">
          <FilesSection />
        </div>
      )}

      {/* Search Icon */}
      <div className="cursor-pointer text-close-button relative search-icon" ref={searchRef}>
        {/* Search Icon */}
        <div
          className={clsx(
            'relative cursor-pointer 2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px] flex items-center py-1 px-2',
            {
              'text-red-button border-b-2 border-red-button': activeMenuIcon === 'search',
              'text-black dark:text-gray-icon': activeMenuIcon !== 'search',
            },
          )}
          onClick={() => {
            handleSearchIconClick();
            handleMenuIconClick('search');
          }}
        >
          <SearchIcon
            width="20"
            height="20"
            className={clsx('fill-icon-light-fill', {
              'fill-red-button': activeMenuIcon === 'search',
              'dark:fill-white': activeMenuIcon !== 'search',
            })}
            role="img"
            aria-label="Search icon"
          />
        </div>

        {showSearchBar && (
          <div
            className={`absolute top-full bg-white text-[#141414] rounded-lg -left-[4.7rem] mt-2 dark:bg-[#1B1C1E] ${
              showHistoryPanel
                ? '2xl:w-[750px] xl:w-[600px] lg:w-[440px] md:w-[290px] sm:w-[240px] w-[250px]'
                : '2xl:w-[790px] xl:w-[700px] lg:w-[530px] md:w-[490px] sm:w-[400px] w-[220px] -left-[70px]'
            }`}
          >
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search"
                ref={searchInputRef}
                className="w-full px-4 2xl:py-1.5 xl:py-1.5 lg:py-1.5 md:py-1.5 sm:py-1 py-1 px-8 pr-8 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[9px] placeholder:text-black rounded-lg bg-transparent border border-gray-500 dark:text-white placeholder:dark:text-white focus:outline-none "
                onKeyDown={(e) => e.key === 'Enter' && handleSearchSubmit()}
              />

              <SearchIcon
                width="20"
                height="20"
                className="fill-icon-light-fill dark:fill-white absolute top-[50%] left-3 transform -translate-y-1/2 2xl:text-[20px] xl:text-[18px] lg:text-[16px] md:text-[14px] sm:text-[12px] text-[10px] text-close-button dark:text-white"
                role="img"
                aria-label="Search icon"
              />

              <CloseIcon
                width="20"
                height="20"
                className="fill-icon-light-fill dark:fill-white absolute right-2 top-2 cursor-pointer 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[9px] text-close-button dark:text-white"
                role="img"
                aria-label="Close icon"
                onClick={closeSearchBar}
              />
            </div>
          </div>
        )}
      </div>

      {/* Menu Icon (More) */}
      <div className="relative" ref={menuRef}>
        {/* Menu Icon (More) */}
        <div
          className={clsx(
            'relative cursor-pointer 2xl:text-[24px] xl:text-[20px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px] flex items-center py-1 px-2',
            {
              'text-red-button border-b-2 border-red-button': activeMenuIcon === 'menu',
              'text-black dark:text-gray-icon': activeMenuIcon !== 'menu',
            },
          )}
          onClick={() => {
            toggleMenu();
            handleMenuIconClick('menu');
          }}
        >
          <MoreIcon
            width="20"
            height="20"
            className={clsx('fill-icon-light-fill', {
              'fill-red-button': activeMenuIcon === 'menu',
              'dark:fill-white': activeMenuIcon !== 'menu',
            })}
            role="img"
            aria-label="More icon"
          />
        </div>

        {isMenuOpen && (
          <div className="absolute w-[8rem] bg-white border border-gray-200 rounded-md shadow-lg dark:bg-black">
            <ul className="py-1">
              <li
                className="flex items-center text-sm font-semibold px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer dark:text-white dark:hover:bg-[#1B1C1E]"
                onClick={handleRenameClick}
              >
                <WriteIcon
                  width="20"
                  height="20"
                  className="fill-icon-light-fill dark:fill-white mr-2 text-close-button dark:text-white"
                  role="img"
                  aria-label="Write icon"
                />
                Rename
              </li>

              <li
                className="flex items-center text-sm font-semibold px-4 py-2 text-gray-800 hover:bg-gray-100 cursor-pointer dark:text-white dark:hover:bg-[#1B1C1E]"
                onClick={handleShareIconClick}
              >
                <ShareIcon
                  width="20"
                  height="20"
                  className="fill-icon-light-fill dark:fill-white mr-2 text-close-button dark:text-white"
                  role="img"
                  aria-label="Share icon"
                />
                Share
              </li>

              <div
                className="flex items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-[#1B1C1E]"
                onClick={toggleExportSubMenu}
              >
                <li className="relative flex items-center text-sm font-semibold px-4 py-2 text-gray-800 dark:text-white">
                  <DownloadIcon
                    width="20"
                    height="20"
                    className="fill-icon-light-fill dark:fill-white mr-2 text-close-button dark:text-white"
                    role="img"
                    aria-label="Download icon"
                  />
                  Export
                </li>

                <ArrowRightIcon
                  width="20"
                  height="20"
                  className="fill-icon-light-fill dark:fill-white ml-2 text-close-button dark:text-white"
                  role="img"
                  aria-label="Arrow right icon"
                />

                {isExportSubMenuOpen && (
                  <ul className="absolute left-full top-20 mt-0 w-[9rem] bg-white border border-gray-200 rounded-md shadow-lg dark:bg-black">
                    <li
                      className="px-4 py-2 hover:bg-[#FFFAFA] text-black text-sm cursor-pointer dark:text-white dark:hover:bg-[#1B1C1E]"
                      onClick={() => toPDF()}
                    >
                      Export to PDF
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-[#FFFAFA] text-black text-sm cursor-pointer dark:text-white dark:hover:bg-[#1B1C1E]"
                      onClick={() => handleDownload('docx')}
                    >
                      Export to DOCX
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-[#FFFAFA] text-black text-sm cursor-pointer dark:text-white dark:hover:bg-[#1B1C1E]"
                      onClick={() => handleDownload('txt')}
                    >
                      Export to TXT
                    </li>
                  </ul>
                )}
              </div>

              <li
                className="flex items-center font-semibold text-sm px-4 py-2 text-black cursor-pointer hover:bg-gray-100 dark:text-white dark:hover:bg-[#1B1C1E]"
                onClick={() =>
                  handleDeleteClick(chats.find((chat) => chat.thread_id === defaultThread))
                }
              >
                <DeleteIcon
                  width="20"
                  height="20"
                  className="fill-icon-light-fill dark:fill-white mr-2 text-close-button dark:text-white"
                  role="img"
                  aria-label="Delete icon"
                />
                Delete
              </li>
            </ul>
          </div>
        )}

        <ShareChat isOpen={isShareModalOpen} onClose={closeShareModal} />

        <RenameModal
          isOpen={isRenameModalOpen}
          onClose={handleCloseRenameModal}
          initialThreadName={threadName}
          onConfirm={handleConfirmRename}
          position={modalPosition}
          onThreadNameChange={setThreadName}
        />
      </div>
    </div>
  );
};

export default ChatMenu;

import React, { useState } from 'react';
import { useChatContext } from '../context/chatContext'; // Import the context
import useAxiosInstance from '../utils/axiosInstance.js';
import { ReactComponent as DeleteIcon } from '../Svg/delete.svg';
import { ReactComponent as EditIcon } from '../Svg/edit.svg';
import { ReactComponent as SearchIcon } from '../Svg/search.svg';
import { ReactComponent as CloseIcon } from '../Svg/close.svg';

const ThreadPanel = ({
  showHistoryPanel,
  toggleCanvas,
  isLoadingThreads,
  chats,
  handleDeleteClick,
  handleChatClick,
  fetchThreads, // Keep this as a prop to refresh the thread list after editing
}) => {
  const { setChats, updateThreadTitle } = useChatContext(); // Access setChats from context
  const [editingThreadId, setEditingThreadId] = useState(null);
  const [newThreadName, setNewThreadName] = useState('');
  const axiosInstance = useAxiosInstance();
  const [searchQuery, setSearchQuery] = useState(''); // New state for search query
  const [activeChatId, setActiveChatId] = useState(null); // New state to track active chat

  const handleEditClick = (thread, e) => {
    e.stopPropagation(); // Prevent button click
    setEditingThreadId(thread.thread_id);
    setNewThreadName(thread.thread_name); // Pre-fill the input with the current name
  };

  const handleEditSave = async (e) => {
    e.stopPropagation(); // Prevent button click
    try {
      const threadId = editingThreadId;
      const updatedName = newThreadName;

      // Update the backend
      const response = await axiosInstance.put(`/api/threads/${threadId}/`, {
        thread_name: updatedName,
      });

      // Optimistically update the UI immediately after the successful API call
      updateThreadTitle(threadId, updatedName); // Use the update function here

      // Now, re-fetch the threads to ensure the updated data is pulled from the server
      await fetchThreads(); // This is the key step to re-sync with the server
    } catch (error) {
      console.error('Error updating thread:', error.response?.data || error.message);
    } finally {
      // Clear the editing state and input field
      setEditingThreadId(null);
      setNewThreadName('');
    }
  };

  // Cancel editing and reset the state
  const handleCancelEdit = (e) => {
    e.stopPropagation(); // Prevent button click
    setEditingThreadId(null);
    setNewThreadName('');
  };

  const filteredChats = chats.filter(
    (chat) =>
      chat.thread_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (chat.chats.length > 0 &&
        chat.chats[chat.chats.length - 1]?.text.toLowerCase().includes(searchQuery.toLowerCase())),
  );

  // Function to highlight matching text
  const highlightText = (text) => {
    if (!searchQuery) return text; // Return original text if no search query
    const parts = text.split(new RegExp(`(${searchQuery})`, 'gi')); // Split text by search query
    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span key={index} className="bg-yellow-300 dark:bg-orange-500 text-black">
          {part}
        </span> // Highlight matching part
      ) : (
        part
      ),
    );
  };

  const handleButtonClick = (threadId) => {
    setActiveChatId(threadId);
    handleChatClick(threadId);
  };

  return (
    <div
      className={`absolute right-0 h-[calc(100vh-64px)] bg-background-gray overflow-y-auto transform transition-transform duration-500 ease-in-out  
          w-[390px] 
          sm:w-[200px] 
          md:w-[240px] 
          lg:w-[300px] 
          xl:w-[330px]
          2xl:w-[350px] 
          ${
            showHistoryPanel ? 'translate-x-0' : 'translate-x-full'
          } dark:bg-black dark:text-gray-icon`}
    >
      <div className="p-4 flex flex-col h-full">
        <div className="flex justify-between items-center">
          <h3 className="text-text-black font-semibold 2xl:text-[16px] xl:text-[15px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[9px] dark:bg-black dark:text-white">
            History
          </h3>
          <CloseIcon
            width="20"
            height="20"
            className="fill-icon-light-fill dark:fill-white cursor-pointer 2xl:text-[24px] xl:text-[22px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px]"
            onClick={() => toggleCanvas(0)}
          />
        </div>
        <hr className="border-t border-text-black my-4 dark:border-white" />
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery} // Bind the input to the search query state
            onChange={(e) => setSearchQuery(e.target.value)} // Update search query on input change
            className="border border-text-black bg-text-gray text-black 2xl:text-[16px] xl:text-[15px] lg:text-[13px] md:text-[10px] sm:text-[8px] text-[6px] 
                         placeholder:text-[#141414] 2xl:placeholder:text-[16px] xl:placeholder:text-[15px] lg:placeholder:text-[14px] md:placeholder:text-[12px] sm:placeholder:text-[10px] placeholder:text-[8px]
                         rounded-md p-2 2xl:pl-10 xl:pl-10 lg:pl-9 md:pl-8 sm:pl-7 pl-6 mb-4 w-full dark:border-white dark:text-white dark:placeholder:text-white"
          />
          <SearchIcon
            role="img"
            aria-label="Search"
            width="20"
            height="20"
            className="absolute 2xl:top-[22px] xl:top-[20px] lg:top-[19px] md:top-[17px] sm:top-[15px] top-[16px] left-3 transform -translate-y-1/2  fill-icon-light-fill dark:fill-white 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[12px] "
          />
        </div>

        {/* Show spinner or threads */}
        <div className="flex flex-col space-y-4 overflow-y-auto">
          {isLoadingThreads ? (
            <div className="flex items-center justify-center">
              <div className="loading-spinner"></div>
            </div>
          ) : (
            filteredChats.map((chat) => (
              <button
                key={chat.thread_id}
                className={`text-left pb-2 px-2 rounded-lg ${
                  activeChatId === chat.thread_id ? 'bg-white dark:bg-black' : ''
                }`}
                onClick={() => handleButtonClick(chat.thread_id)}
              >
                <div className="flex my-3 justify-between">
                  <div className="2xl:text-[12px] xl:text-[11px] lg:text-[11px] md:text-[10px] sm:text-[8px] text-[6px]">
                    {new Date(chat.created_at).toLocaleString()}
                  </div>
                  <div className="flex">
                    <EditIcon
                      role="img"
                      aria-label="Edit"
                      width="20"
                      height="20"
                      className="fill-icon-light-fill dark:fill-white cursor-pointer 2xl:mr-6 xl:mr-5 lg:mr-4 md:mr-3 sm:mr-2 mr-1 2xl:text-[20px] xl:text-[18px] lg:text-[16px] md:text-[14px] sm:text-[13px] text-[13px]"
                      onClick={(e) => handleEditClick(chat, e)}
                    />
                    <DeleteIcon
                      role="img"
                      aria-label="Delete"
                      width="20"
                      height="20"
                      className="fill-icon-light-fill dark:fill-white cursor-pointer 2xl:mr-6 xl:mr-5 lg:mr-4 md:mr-1 sm:mr-1 mr-1 2xl:text-[20px] xl:text-[18px] lg:text-[16px] md:text-[14px] sm:text-[13px] text-[13px]"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteClick(chat);
                      }}
                    />
                  </div>
                </div>
                {editingThreadId === chat.thread_id ? (
                  <div className="flex flex-col items-start">
                    <input
                      type="text"
                      value={newThreadName}
                      onChange={(e) => {
                        e.stopPropagation();
                        setNewThreadName(e.target.value);
                      }}
                      className="border-2 border-b-red-button bg-transparent rounded-lg 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[10px] text-[9px] 2xl:p-1.5 xl:p-1.5 lg:p-1.5 md:p-1 sm:p-1 p-1.5 mb-2 w-full dark:text-white"
                    />
                    <div className="flex space-x-4 w-full">
                      <button
                        onClick={(e) => handleEditSave(e)}
                        className="2xl:px-3 2xl:py-1 xl:px-3 xl:py-1 lg:px-2.5 lg:py-0.5 md:px-2 md:py-0.5 sm:px-2 sm:py-0.5 px-2 py-0.5 
                        text-white bg-red-button 
                        2xl:rounded-lg xl:rounded-lg lg:rounded-lg md:rounded-md sm:rounded-md rounded-md 
                        2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[11px]"
                      >
                        Save
                      </button>
                      <button
                        onClick={(e) => handleCancelEdit(e)}
                        className="2xl:px-3 2xl:py-1 xl:px-3 xl:py-1 lg:px-2.5 lg:py-0.5 md:px-2 md:py-0.5 sm:px-2 sm:py-0.5 px-2 py-0.5 
                        bg-background-gray text-close-button
                        2xl:rounded-lg xl:rounded-lg lg:rounded-lg md:rounded-md sm:rounded-md rounded-md 
                        2xl:text-[15px] xl:text-[15px] lg:text-[14px] md:text-[13px] sm:text-[12px] text-[11px]"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <h4 className="font-semibold text-black cursor-pointer 2xl:text-[18px] xl:text-[17px] lg:text-[16px] md:text-[14px] sm:text-[12px] text-[10px] dark:bg-black dark:text-white">
                      {highlightText(chat.thread_name)} {/* Highlight matching text */}
                    </h4>
                    <p
                      className={`text-gray-500 2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[10px] sm:text-[8px] text-[6px] dark:bg-black dark:text-white`}
                    >
                      Last message:{' '}
                      {chat.chats.length > 0
                        ? chat.chats[chat.chats.length - 1]?.text
                            .split(' ')
                            .slice(0, 10)
                            .join(' ') +
                          (chat.chats[chat.chats.length - 1]?.text.split(' ').length > 10
                            ? '...'
                            : '')
                        : 'No messages yet'}
                    </p>
                  </>
                )}
              </button>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ThreadPanel;

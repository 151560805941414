import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config/authConfig';
import { FaArrowRight } from 'react-icons/fa';
import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import dv8 from '../assets/dv8.png';
import { useChatContext } from '../context/chatContext';

const RealSignUp = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { LastVisitedThreadId } = useChatContext();

  const handleLoginWithMicrosoft = async (event) => {
    event.preventDefault();
    if (isAuthenticated) {
      navigate(LastVisitedThreadId ? `/threads/${LastVisitedThreadId}` : '/threads');
      return;
    }
    try {
      await instance.loginRedirect({
        ...loginRequest,
      });
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className="relative min-h-full h-[100vh] flex items-center justify-center">
      {/* Background video */}
      <video className="absolute top-0 left-0 w-full h-full object-cover z-0" autoPlay loop muted>
        <source src="/bg_video2.mp4" type="video/mp4" />
      </video>
      <img
        src={dv8}
        alt="User"
        className="absolute top-4 left-4 2xl:w-[250px] 2xl:h-[96px] xl:w-[230px] xl:h-[90px] lg:w-[200px] lg:h-[80px] md:w-[180px] md:h-[70px] sm:w-[170px] sm:h-[60px] w-[130px] h-[55px] z-10"
      />
      {/* Content */}
      <div className="z-10 flex flex-col items-center justify-center">
        <div className="2xl:mt-[750px] xl:mt-[450px] lg:mt-[410px] md:mt-[420px] sm:mt-[450px] mt-[450px]">
          <form
            onSubmit={handleLoginWithMicrosoft}
            className="space-y-6 flex flex-col items-center"
          >
            <button
              type="submit"
              className="flex 2xl:w-[289px] 2xl:h-[56px] xl:w-[270px] xl:h-[54px] lg:w-[250px] lg:h-[45px] md:w-[230px] md:h-[40px] sm:w-[210px] sm:h-[38px] w-[200px] h-[35px] justify-center items-center mb-8 rounded-full bg-black py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#FF0000] 
              2xl:text-[20px] xl:text-[19px] lg:text-[18px] md:text-[17px] sm:text-[16px] text-[14px]"
            >
              Welcome to Elysium
              <FaArrowRight className="ml-2 mt-1 2xl:text-[24px] xl:text-[22px] lg:text-[20px] md:text-[18px] sm:text-[16px] text-[14px]" />
            </button>
          </form>
          <div className="text-center">
            <div className="text-white font-normal my-2 2xl:text-[14px] xl:text-[13px] lg:text-[12px] md:text-[11px]  sm:text-[10px] text-[8px]">
              Powered by
            </div>
            <img
              alt="Your Company"
              src="/image.png"
              className="mx-auto  w-auto 2xl:h-[96px] xl:h-[93px] lg:h-[90px] md:h-[80px] sm:h-[70px] h-[65px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealSignUp;

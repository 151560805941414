import React, { useRef, useEffect, useState } from 'react';

const AgentMenu = ({ onClose, agents, onSelectAgent }) => {
  const menuRef = useRef(null);
  const [selectedAgents, setSelectedAgents] = useState(
    JSON.parse(localStorage.getItem('selectedAgents')) || [],
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleAgentClick = (agent) => {
    setSelectedAgents(() => {
      // Set the clicked agent as the only active agent
      const updated = [agent.name];
      localStorage.setItem('selectedAgents', JSON.stringify(updated));
      onSelectAgent(updated); // Send the updated list
      return updated;
    });
  };

  return (
    <div
      ref={menuRef}
      className="absolute 2xl:top-14 2xl:left-2 xl:top-12 xl:left-2 lg:top-12 lg:left-2 md:top-11 md:left-2 sm:top-10 sm:left-2 top-8 left-1 bg-white rounded-lg shadow-lg 2xl:w-[128px] xl:w-[120px] lg:w-[110px] md:w-[105px] sm:w-[100px] w-[90px] z-20 dark:bg-black"
    >
      {agents.map((agent, index) => (
        <div
          key={index}
          onClick={() => handleAgentClick(agent)}
          className={`flex items-center 2xl:p-2 xl:p-2 lg:p-2 md:p-1.5 sm:p-1.5 p-1.5 cursor-pointer
            ${
              selectedAgents.includes(agent.name)
                ? 'bg-[#FFFAFA] dark:bg-[#FF00000D]'
                : 'hover:bg-[#FFFAFA] dark:hover:bg-[#FF00000D]'
            }`}
        >
          <img
            src={agent.image}
            alt={agent.name}
            className="2xl:w-[32px] 2xl:h-[32px] xl:w-[31px] xl:h-[31px] lg:w-[30px] lg:h-[30px] md:w-[28px] md:h-[28px] sm:w-[26px] sm:h-[26px] w-[20px] h-[20px] rounded-full object-cover mr-2"
          />
          <span
            className={`2xl:text-[16px] xl:text-[14px] lg:text-[13px] md:text-[12px] sm:text-[11px] text-[10px] ${
              selectedAgents.includes(agent.name) ? 'text-red-button' : 'text-black dark:text-white'
            }`}
          >
            {agent.name}
          </span>
        </div>
      ))}
    </div>
  );
};

export default AgentMenu;

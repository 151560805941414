import React from 'react';
import dv8RotationGif from '../assets/dv8Rotation.gif';
import typingGifLight from '../assets/typing3.gif';
import typingGifDark from '../assets/typing4.gif';
import { FaCheck } from 'react-icons/fa6';

const AnalysisComponent = ({ step }) => {
  const isDarkMode = localStorage.getItem('darkMode') === 'true';

  return (
    <div className="flex flex-col items-start justify-start p-6 space-y-2">
      {/* Analyzing data */}
      {step === 0 || (step > 0 && step < 4) ? (
        <div className="flex items-center space-x-2">
          {step === 0 && (
            <>
              <img src={dv8RotationGif} alt="Analyzing" className="w-[20px] animate-spin" />
              <p className="text-[13px] font-semibold">Analyzing data...</p>
            </>
          )}
          {step > 0 && step < 4 && (
            <>
              <FaCheck className="inline-block text-green-500" />
              <p className="text-[13px] font-semibold">Analyzing data</p>
            </>
          )}
        </div>
      ) : null}

      {/* Generating response */}
      {step === 2 || (step > 2 && step < 4) ? (
        <div className="flex items-center space-x-2">
          {step === 2 && (
            <>
              <img
                src={dv8RotationGif}
                alt="Generating response"
                className="w-[20px] animate-spin"
              />
              <p className="text-[13px] font-semibold">Generating response...</p>
            </>
          )}
          {step === 3 && (
            <>
              <FaCheck className="inline-block text-green-500" />
              <p className="text-[13px] font-semibold">Generating response</p>
            </>
          )}
        </div>
      ) : null}

      {/* Typing... */}
      {step === 4 ? (
        <div className="flex items-center space-x-2">
          <img
            src={isDarkMode ? typingGifDark : typingGifLight}
            alt="Typing"
            className="w-[32px]"
          />
          <p className="text-[13px] font-semibold">Typing...</p>
        </div>
      ) : null}
    </div>
  );
};

export default AnalysisComponent;

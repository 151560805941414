import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../config/authConfig';

// Function to get the token and configure Axios
const useAxiosInstance = () => {
  const { instance, accounts } = useMsal();

  // get baseurl from env
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // Use environment variable
  });

  axiosInstance.interceptors.request.use(
    async (config) => {
      // Get the token silently before making any request
      const request = {
        ...loginRequest,
        account: accounts[0],
        headers: {
          'ngrok-skip-browser-warning': '69420',
        },
        // Use the logged-in user's account
      };

      try {
        await instance.initialize();
        const response = await instance.acquireTokenSilent(request);
        const account = accounts[0];
        // Add the token to headers
        config.headers.Authorization = `Bearer ${response.accessToken}`;

        sessionStorage.setItem('msal.account.keys', JSON.stringify(account));
        sessionStorage.setItem('accesstoken', JSON.stringify(response.accessToken));
      } catch (error) {
        console.error('Error acquiring token silently:', error);
        // Optionally handle token acquisition failure
        // instance.acquireTokenRedirect(request);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

export default useAxiosInstance;

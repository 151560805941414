import React from 'react';
import dev8 from '../assets/dev8.png';

const FeatureUnavailable = () => {
  return (
    <div className="flex items-start space-x-4 p-4">
      {/* Image */}
      <img
        src={dev8}
        alt="Unavailable"
        className="2xl:w-[41px] 2xl:h-[41px] xl:w-[36px] xl:h-[36px] lg:w-[34px] lg:h-[34px] md:w-[30px] md:h-[30px] sm:w-[28px] sm:h-[28px] w-[26px] h-[26px] mt-1"
      />

      {/* Message Content */}
      <div>
        <h1 className="font-bold text-black mb-1 dark:text-white 2xl:text-[16px] xl:text-[15px] lg:text-[13px] md:text-[12px] sm:text-[11px] text-[10px]">
          This feature is currently unavailable...
        </h1>
        <p className="text-black dark:text-white 2xl:text-[16px] xl:text-[14px] lg:text-[12px] md:text-[11px] sm:text-[10px] text-[9px]">
          Apologies, this feature is currently unavailable. Please check back later or contact
          support for <br />
          more information.
        </p>
      </div>
    </div>
  );
};

export default FeatureUnavailable;

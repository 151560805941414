import React, { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { FaCheck } from 'react-icons/fa6';

const RenameModal = ({
  isOpen,
  onClose,
  initialThreadName,
  onConfirm,
  position,
  onThreadNameChange,
}) => {
  const [threadName, setThreadName] = useState(initialThreadName);

  useEffect(() => {
    setThreadName(initialThreadName);
  }, [initialThreadName]);

  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm(threadName);
    onClose();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleConfirm();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="w-[500px] p-3"
        style={{ position: 'absolute', top: position.top, left: position.left }}
      >
        <div className="relative rounded-lg bg-white border border-red-500 dark:bg-black">
          <input
            type="text"
            value={threadName}
            onChange={(e) => {
              setThreadName(e.target.value);
              onThreadNameChange(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            className="bg-transparent rounded-lg p-2 w-full pr-10 text-black dark:text-white"
          />
          <FaCheck
            onClick={handleConfirm}
            className="absolute right-10 top-3 cursor-pointer text-close-button dark:text-white"
          />
          <RxCross2
            onClick={onClose}
            className="absolute right-2 top-3 cursor-pointer text-close-button dark:text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default RenameModal;
